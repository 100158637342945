@track-w: 100%;
@track-h: 0.25em;
@thumb-d: 1.3em;

.track() {
  box-sizing: border-box;
  border: none;
  width: @track-w;
  height: @track-h;
  background: @borderColor;
}

.thumb() {
  box-sizing: border-box;
  border: none;
  width: @thumb-d;
  height: @thumb-d;
  border-radius: 50%;
  background: @lkq;
  cursor: pointer;

  &:hover {
    background: darken(@lkq, 0.7);
  }
}

.range-slider {
  &__wrap {
    display: flex;
    width: @track-w;
  }
  &__slider {
    outline: none;
    flex: 1;

    &,
    &::-webkit-slider-thumb {
      -webkit-appearance: none;
    }

    margin: 0 1em;
    padding: 0;
    height: @thumb-d;
    background: transparent;
    font: 1em/1 arial, sans-serif;

    &::-webkit-slider-runnable-track {
      .track();
    }
    &::-moz-range-track {
      .track();
    }
    &::-ms-track {
      .track();
    }

    &::-webkit-slider-thumb {
      margin-top: 0.5 * (@track-h - @thumb-d);
      .thumb();
    }
    &::-moz-range-thumb {
      .thumb();
    }
    &::-ms-thumb {
      margin-top: 0;
      .thumb();
    }

    &::-ms-tooltip {
      display: none;
    }

    &--no-labels {
      margin: 0;
    }
  }
  &__label {
    display: none !important;
    flex-shrink: 0;
    font-weight: 700;

    &--show {
      display: inline-block !important;
    }
  }
}
