.flex--center {
  display: flex;
  align-items: center;
  justify-content: center;
}

.flex--center-end {
  display: flex;
  align-items: center;
  justify-content: flex-end;
}