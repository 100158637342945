.d-block {
  display: block !important;
}

.d-flex {
  display: flex !important;
}

.d-inline {
  display: inline !important;
}

.d-inline-block {
  display: inline-block !important;
}

.d-none {
  display: none !important;
}

.d-hidden {
  visibility: hidden !important;
}
