/*******************************
         User Overrides
*******************************/
.ui.form input:not([type]),
.ui.form input[type="date"],
.ui.form input[type="datetime-local"],
.ui.form input[type="email"],
.ui.form input[type="number"],
.ui.form input[type="password"],
.ui.form input[type="search"],
.ui.form input[type="tel"],
.ui.form input[type="time"],
.ui.form input[type="text"],
.ui.form input[type="file"],
.ui.form input[type="url"],
.ui.form textarea {
    border: 1px solid #dedede;
}
.ui.form .read-only textarea {
    cursor: not-allowed;
    background-color: #F3F3F3;
    resize: none;
}

.ui.form input[type="date"]:hover,
.ui.form input[type="datetime-local"]:hover,
.ui.form input[type="email"]:hover,
.ui.form input[type="number"]:hover,
.ui.form input[type="password"]:hover,
.ui.form input[type="search"]:hover,
.ui.form input[type="tel"]:hover,
.ui.form input[type="time"]:hover,
.ui.form input[type="text"]:hover,
.ui.form input[type="file"]:hover,
.ui.form input[type="url"]:hover,
.ui.form textarea:hover {
    border: 1px solid #dedede;
}



.ui.form input:not([type]):focus,
.ui.form input[type="date"]:focus,
.ui.form input[type="datetime-local"]:focus,
.ui.form input[type="email"]:focus,
.ui.form input[type="number"]:focus,
.ui.form input[type="password"]:focus,
.ui.form input[type="search"]:focus,
.ui.form input[type="tel"]:focus,
.ui.form input[type="time"]:focus,
.ui.form input[type="text"]:focus,
.ui.form input[type="file"]:focus,
.ui.form input[type="url"]:focus {
    border-color: #dedede;
}
.ui.form textarea:focus {
    border-color: #dedede;
}
