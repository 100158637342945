.vehicle-account-card {
  .vehicle-account-card__title {
    float: right;
  }
}

.consult-vehicle-fuel-table {
  border-collapse: collapse;
  tbody {
    tr:last-of-type {
      border-bottom: 0 !important;
      td {
        border-bottom: 0 !important;
      }
    }
  }
}

.vehicle-vin-clipboard-input {
  position: absolute;
  margin-top: -2000px;
  margin-left: -2000px;
}

.vehicle-summary-brandicon-correction {
  position: relative;
  top: 3px;
  margin-right: 10px;
}

.haynespro-recall_label-container{
  float: right;
}

.haynespro-recall_label-count{
  margin: -.5rem 0 !important;
}
