// semantic-ui styles
// resolves to <root>/packages/styling/semantic.less
@import '~@cbgms/styling/semantic.less';

//Configuration
@import 'configuration/semantic-variables';
@import 'configuration/sprites';

//Components
@import 'components/cookie-consent';
@import 'components/absence-schedule/overrides';
@import 'components/summary';
@import 'components/icons';
@import 'components/calendar';
@import 'components/cart';
@import 'components/colorpicker';
@import 'components/dateTimePicker';
@import 'components/scheduler';
@import 'components/grid';
@import 'components/legacy-grids';
@import 'components/licenseplate';
@import 'components/vin';
@import 'components/list';
@import 'components/quotation';
@import 'components/main-search-popup';
@import 'components/vehicle';
@import 'components/workorder';
@import 'components/apk';
@import 'components/label';
@import 'components/dashboard';
@import 'components/stats';
@import 'components/external-companies';
@import 'components/stock';
@import 'components/invoice-layout';
@import 'components/supplier-list';
@import 'components/logo';
@import 'components/terms-conditions';
@import 'components/my-workorder';
@import 'components/title';
@import 'components/form-buttons';
@import 'components/messages';
@import 'components/productgroups';
@import 'components/range-slider';
@import 'components/progress-bar';
@import 'components/carousel';
@import 'components/drag-and-drop-target';
@import 'components/vehicle-validation';
@import 'components/order';
@import 'components/main-search-buttons.less';
@import 'components/exportbutton.less';

// Local helpers
@import 'helpers/sort-toggle';
@import 'helpers/table';
@import 'helpers/type';
@import 'helpers/tabs';
@import 'helpers/dropdown';
@import 'helpers/loader';
@import 'helpers/masonry';
@import 'helpers/flex';
@import 'helpers/pointers';
@import 'helpers/hoverstates';

// Global Component Styling
@import '~gms/src/app/settings/components/Media/Media';
@import '~@cbgms/components/grid/Grid';
@import '~@cbgms/components/grid/Filter/PopupDropdown';
@import '~@carsys/ui/src/styles/typography/typography';
@import 'content';

@import 'ui';

// Internet Exploder

@import 'helpers/ie';

.emoji-flag {
  font-family: 'EmojiFlag';
}
