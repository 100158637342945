/*******************************
        User Overrides
*******************************/
.inspection-defects-list {
  & .accordion {
    box-shadow: none !important;
  }

  & .accordion > .content {
    padding-right: 0 ;
  }

  &__defect {
    color: @regularTextColor ;
    cursor: unset ;
    font-weight: normal ;

    &:hover {
      color: @regularTextColor;
    }
  }
}
