.cbgms-grid.table {
  border: 1px solid #e1e1e1;
  border-spacing: 0;
  border-width: 0;
}

.cbgms-grid.table > tbody > tr:hover {
  cursor: pointer;
}

.cbgms-grid.table > tbody > tr:hover > td {
  background-color: #f3f3f3;
}

.cbgms-grid.table th,
.cbgms-grid.table td {
  padding: 0.5em;
  border-right: 1px solid #e1e1e1;
  border-bottom: 1px solid #e1e1e1;
  max-width: 300px;
}

.cbgms-grid.table td {
  /* For Firefox */
  white-space: pre-wrap;
  word-break: normal;
  /* For Chrome and IE */
  word-wrap: break-word;
  .button {
    /* For Firefox */
    white-space: pre-wrap;
    word-break: normal;
    /* For Chrome and IE */
    word-wrap: break-word;
  }
}

.cbgms-grid.table th {
  background-color: #f3f3f3;
  text-align: left;
  position: relative;
  z-index: 999;
}

.cbgms-grid.wrapper {
  display: flex;
  flex: 1;
  flex-direction: column;
  height: 100%;
  width: 100%;
}

.cbgms-grid.border {
  border: 1px solid #e1e1e1;
  border-radius: 0.28571429em;
  height: 100%;
  overflow: hidden;
}

.cbgms-grid.scroller {
  flex: 1;
  height: 100%;
  overflow: auto;
}

.cbgms-grid.sort-icon {
  float: right;
  margin-left: 5px;
}

.key-value-pair .left.aligned.column {
  font-weight: bold;
}

.key-value-pair .grid {
  .column:not(.row) {
    padding-bottom: 0 !important;
  }
}

.key-value-pair .stackable.grid {
  .column:not(.row) {
    .onTablets({ text-align: center !important; padding-bottom: 0 !important; });
  }
  .left.aligned.column {
    .onTablets({ padding-top: 0 !important; });
  }
}

.plain-table {
  .table thead th {
    transform: translate(0px, 0px);
    min-width: 100px;
    z-index: initial;
  }
}

.vehicle-summary-grid-column {
  padding-top: 1rem;

  .ui.dropdown > .text {
    white-space: nowrap;
    display: block;
    overflow: hidden;
    text-overflow: ellipsis;
  }
}

.scrollable {
  display: block;
  overflow: auto;
  max-height: 300px;
  width: 100%;
}

.scrollable thead th {
  position: sticky;
  top: 0;
  z-index: 1;
}
