.basic-dropdown-button {
  -moz-box-shadow: none !important;
  -webkit-box-shadow: none !important;
  background: @white !important;
  border: 1px solid @inputBorderColor !important;
  box-shadow: none !important;
  font-size: 12px !important;
  font-weight: bold !important;
  line-height: 38px !important;
  margin-left: 0 !important;
  margin-right: 0.25em !important;
  margin-bottom: 0 !important;
  padding: 0 1.5em 0 1.5em !important;
  text-transform: uppercase !important;
  &:hover{
    background: @buttonBackgroundHoverColor !important;
  }

  &.workorder-buttons {
    line-height: 28px !important;
  }
}
