// custom scheduler styles

.b-react-scheduler-container,
.scheduler-container-segment {
  .ui.grid > .row > [class*='eleven wide'].column,
  .ui.grid > .column.row > [class*='eleven wide'].column,
  .ui.grid > [class*='eleven wide'].column,
  .ui.column.grid > [class*='eleven wide'].column {
    width: 90% !important;
    margin-left: 6px;
  }

  .ui.grid > .row > [class*='one wide'].column,
  .ui.grid > .column.row > [class*='one wide'].column,
  .ui.grid > [class*='one wide'].column,
  .ui.column.grid > [class*='one wide'].column {
    width: 4.25% !important;
    margin-top: 10px;
  }

  .registrationAndBrandItem {
    padding-top: 5px;
    padding-bottom: 5px;
    padding-right: 20px;
  }

  .orderItem-title {
    padding-bottom: 3px;
    display: flex;
    align-items: center;

    i {
      font-size: 18px;
      color: #ffb368;
    }
  }

  .diaryItem {
    margin-left: 10px;
  }

  .lineStatusWrapper {
    margin-left: 24px;
    font-size: 16px;
    padding-top: 5px;
  }

  .mappingAndLineStatus {
    font-size: 16px;
    color: #cc0033;
    padding-top: 2px;
    padding-left: 5px;
  }

  .registrationAndRemarkItem {
    width: 150px;
    text-overflow: ellipsis;
    overflow: hidden;
  }

  .b-sch-event {
    border-radius: 3px;
    box-shadow: 0 1px 1px 0 rgba(0, 0, 0, 0.25);
  }

  #main {
    display: flex;
    flex-direction: row;
    flex: 1 1 100%;
  }

  #main .b-resourceheader {
    height: 100%;
  }

  .b-grid-header {
    height: 57px;
  }

  .b-unassigned-class.b-drag-proxy {
    background: green;
    color: #fff;
    border-radius: 3px;
    box-shadow: 0 1px 1px 0 rgba(0, 0, 0, 0.25);
  }

  .b-unassigned-class.b-drag-proxy.b-drag-invalid {
    background: red;
  }

  .b-unassigned-class.b-drag-proxy.b-horizontal {
    align-items: flex-start;
    padding-left: 0.5em;
  }

  .b-unassigned-class.b-drag-proxy.b-vertical {
    justify-content: flex-start;
    padding-top: 0.5em;
  }

  .b-aborting.b-unassigned-class {
    transition: transform 0.3s !important;
    background: red;
    position: absolute !important;
    z-index: 10000;
    pointer-events: none;
    opacity: 0.8;
    box-sizing: border-box;
  }

  .b-grid-header {
    height: auto !important;
  }

  .b-sch-event:not(.b-milestone) .b-sch-event-content {
    overflow: visible;
  }

  // vertical
  .b-resourceheader-cell {
    display: block !important;
  }

  .scheduler-column-cell {
    display: flex;
    flex-direction: column;
    white-space: normal;
    word-wrap: break-word;
    word-break: break-all;
    padding: 10px;
    width: 100%;
    .mechanic-name-container {
      display: flex;
      justify-content: space-between;
      .mechanic-name {
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }
      .mechanic-name-button {
        margin-left: 30px;
      }
    }
    .mechanic-functions-container {
      display: flex;
      .mechanic-functions {
        margin-top: 3px;
        font-size: 10pt;
      }
      .mot-position {
        margin-left: 5px;
        margin-top: 2px;
      }
    }
  }

  .scheduler-header-cell {
    &:extend(.scheduler-column-cell);
    height: auto;

    .scheduler-header-cell-main-content {
      display: flex;
      flex-direction: row;
      justify-content: space-between !important;
      width: 100%;
      .header-button {
        height: 50%;
        margin-top: 100%;
      }
      .visible-week {
        visibility: visible;
        padding-bottom: 5px;
      }
      .no-visible-week {
        visibility: hidden;
      }
      .day-label {
        display: flex;
        height: 20px;
        line-height: 20px;
        white-space: nowrap;
        color: #444;
        margin-top: 15px;
        .visible-week {
          visibility: visible;
        }
        .no-visible-week {
          visibility: hidden;
        }
        .highlight {
          background: #c2c2c2 !important;
          color: white;
          padding: 0 7px;
        }
        &.disabled {
          color: darkgray;
        }
        .capacity-header {
          display: flex;
          margin-left: 10px;
          line-height: 12px;
          .capacity-used {
            &.green {
              color: #009900;
            }
            &.red {
              color: red;
            }
            font-weight: normal;
            padding-right: 5px;
            padding-top: 5px;
          }
          .capacity-total {
            font-weight: normal;
            padding-top: 5px;
          }
        }
      }
      .additional-data-header {
        display: flex;
        padding-top: 15px;
        margin-left: -13px;
        .row-data {
          padding-right: 40px;
          display: flex;
          .appointment-amount {
            margin-top: 5px;
          }
        }
        .scheduler-absence-container {
          display: contents;
        }
        .mot-amount {
          margin-top: 5px;
          padding-left: 10px;
        }
      }

      span:nth-child(1) {
        width: 20%;
      }

      span:nth-child(2) {
        display: flex;
        flex-direction: row;
        justify-content: center !important;
        width: 60%;
      }

      span:nth-child(3) {
        display: flex;
        flex-direction: row;
        justify-content: flex-end !important;
        width: 20%;
      }

      .time-progress-bar{
        width: 100% !important;
        span{
          width: 100% !important;
        }
      }
    }

    .scheduler-header-cell-additional-content {
      display: flex;
      flex-direction: row;
      justify-content: center;
    }
  }

  // GMS-8727 horizontal && vertical: header: vertical lines
  .b-resourceheader-cell,
  .b-grid-header-container .b-sch-timeaxiscolumn .b-sch-header-timeaxis-cell {
    border-right: 1px solid #d1d1d1;
  }

  .scheduler-internal-top-bar {
    margin-bottom: 10px;
  }
  .b-sch-event:not(.b-milestone).b-sch-event-selected,
  .b-sch-event:not(.b-milestone).b-sch-event-selected * {
    color: black;
  }
}

#scheduler-buttons-top-bar {
  .ui.basic.buttons {
    background: @white !important;
  }

  margin-top: 0.5rem !important;
}

.scheduler-appointment-item {
  width: 100%;

  &.center {
    text-align: center;
  }

  &.mechanics-view {
    padding-left: 0.75em;
  }

  .week-view {
    font-family: 'kentekenregular';
    font-size: 11px;
    margin: 0;
    position: absolute;
    top: 50%;
    left: 50%;
    -ms-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
    &.blink {
      color: red;
      animation: blink 3s linear infinite;
    }
    .NL {
      font-family: 'kentekenregular';
    }
    .BE {
      font-family: 'kentekenbe';
    }
    .CZ {
      font-family: 'kentekencz';
    }
    .CZ {
      font-family: 'kentekende';
    }
    .FR {
      font-family: 'kentekenfr';
    }
    .IT {
      font-family: 'kentekenit';
    }
    .GB {
      font-family: 'kentekenuk';
      font-size: 14px;
    }
    @keyframes blink {
      25% {
        opacity: 0.25;
      }
      37% {
        opacity: 0.37;
      }
      50% {
        opacity: 0.5;
      }
      62% {
        opacity: 0.62;
      }
      75% {
        opacity: 0.75;
      }
      87% {
        opacity: 0.87;
      }
      100% {
        opacity: 1;
      }
    }
  }

  .row-style {
    padding: 0 !important;

    .column-style {
      border: 1px solid rgba(34, 36, 38, 0.1);

      &.with-icon {
        padding-left: 0;
        padding-right: 0;

        .status {
          height: calc(100% / 3);
          border-bottom: 1px solid rgba(34, 36, 38, 0.1);
        }
      }
    }

    .appointment-color {
      width: 9px !important;
    }

    .appointment-info {
      width: 72% !important;
      padding-right: 0 !important;
    }
  }
}

#scheduler-plan-appointment-top-bar,
#scheduler-buttons-top-bar {
  .ui.basic.buttons {
    background: @white !important;
  }

  margin-top: 0.5rem !important;
}
.b-grid-cell {
  .b-selected {
    min-width: 75px !important;
  }
}

.b-popup-header {
  background-color: var(--mainColour) !important;
  color: #fff;
}
#open-workorder-button {
  width: 90%;
  height: 100%;
  margin-left: 5% !important;
  padding: 0 !important;
  box-shadow: none;
}
#open-workorder-button:active {
  background-color: white;
  color: black !important;
}

.scheduler-month-item {
  background-color: white;
  color: #838181;
  .item-container {
    display: flex;
    flex-direction: column;
    &.fully-booked {
      border-top: 3px solid red;
    }
    &.not-fully-booked {
      border-top: 3px solid green;
    }
    .data-row {
      display: flex;
      justify-content: space-between;
      padding: 0px 10px 5px 10px;
      font-size: 12px;
      font-weight: 500;
      .green-capacity {
        color: green;
        padding-right: 4px;
      }
      .red-capacity {
        color: red;
        padding-right: 4px;
      }
    }
    .shop-closed {
      display: flex;
      justify-content: center;
      align-items: center;
      margin-top: 20%;
      margin-bottom: 25%;
    }
  }
}

.list-unplanned-workorder {
  .title {
    text-align: center;
    padding: 20px;
    border-bottom: 1px solid #d1d1d1;
  }
  .unplanned-workorder {
    outline: none;
    cursor: grabbing;
    min-height: 60px;
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    border-bottom: 1px solid #d1d1d1;
    padding: 10px;
    -webkit-touch-callout: none; /* iOS Safari */
    -webkit-user-select: none; /* Safari */
    -khtml-user-select: none; /* Konqueror HTML */
    -moz-user-select: none; /* Old versions of Firefox */
    -ms-user-select: none; /* Internet Explorer/Edge */
    user-select: none; /* Non-prefixed version, currently supported by Chrome, Edge, Opera and Firefox */
  }
  .unplanned-workorder:hover {
    color: white;
    background: var(--mainColour);
  }
}
