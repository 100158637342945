// .stop-body-scroll class is applied by backdrops
// this will stop scrolling by disabling scroll on div#root 
// body itself should keep their scroll bar to stop content from shifting when scroll appears/disappears


body.stop-body-scroll {
  // this assumes there's a scroll bar on the page already, we want keep it on body tag
  overflow-y: scroll; 
}


body.stop-body-scroll.no-bar {
  // this assumes there was no scroll bar on the page.
  // make sure we don't add one.
  overflow-y: auto; 
}

body.stop-body-scroll #root {
  // this stop scrolling on #root element
  overflow-y: hidden;
  height: 100%;
}

