/*******************************
    User Variable Overrides
*******************************/


.summary-header-account-list__info{
  .ui.list{
    padding-left:0;
  }
}
