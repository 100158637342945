/*******************************
         Site Overrides
*******************************/

.product-filter-menu {
  > .vertical.menu {
    display: flex;
  }
  .ui.vertical.menu .item.filter-item-closed {
    > .header:not(.ui) {
      margin-bottom: 0;
    }
    .menu {
      display: none;
    }
  }
}

.workorder.dropdown-menu-ui {
  border-color: @solidBorderColor;
}

