.ordercart_segment {
  padding-top   : 0 !important;
  padding-bottom: 0 !important;
}
 
.ordercart_item-container {
  max-height   : 50vh;
  overflow     : hidden auto;
  padding-right: 1rem; //Needs padding right to leave space for scrollbars
}

.ordercart-totals_spaced-right {
  padding-right: 4rem !important;
}

.ordercart-totals {
  padding         : 0.7rem 1rem 0.7rem 1rem;
  border          : 1px solid rgba(0, 0, 0, 0.1);
  border-radius   : 5px;
  background-color: white;
  display         : inline-block;
  cursor          : default;

  .ordercart-totals__amount {
    position                  : absolute;
    background-color          : #eeeeee;
    display                   : block;
    right                     : 8px;
    top                       : 1px;
    bottom                    : 1px;
    width                     : 40px;
    border-top-right-radius   : 3px;
    border-bottom-right-radius: 3px;
    text-align                : center;
    padding-top               : 1.4rem;
    line-height               : 0.6rem;

    .icon {
      padding-left: 2px;
    }

    span {
      font-size     : 0.7rem;
      vertical-align: middle;
      text-align    : center;
      color         : white;
      width         : 2rem;
      position      : absolute;
      top           : 18px;
      right         : 7px;
    }
  }

  .ordercart-totals__icon {
    position: relative;
    bottom  : 6px;
    right   : 2px;
  }

  .ordercart-totals__excl {
    font-size: 0.8rem;
  }

  .ordercart-totals__incl {
    margin-top: 0 !important;
  }
}

.ordercart-totals__hover {
  cursor: pointer;

  &:hover {
    background-color: rgb(247, 247, 247);
  }
}

.ordercart_footer-total {
  margin-top : 0.5rem !important;
  margin-left: 0.2rem !important;
}

.ordercart_row-add-divider>td {
  border-top: 1px solid rgb(238, 238, 238) !important;
}


#shopping-cart-popper{
  cursor: initial;
}


.ordercart_totals-amount {
  font-size    : 1.2rem;
  display      : block;
  text-align   : right;
  padding-right: 5px;
  float        : left;
  font-weight  : normal;
}


.ordercart_supplierParams {
  border-top   : 1px solid var(--mainColour) !important;
  margin-bottom: 2rem;
  padding-top  : 2rem;
}
