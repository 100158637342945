/*******************************
    User Variable Overrides
*******************************/

.ui.dropdown {
    a:hover{
        text-decoration:none;
    }
}

/* Warning */
@warningBackground: @warningBackgroundColor;
@warningColor: @warningTextColor;
@warningBorder: @warningBorderColor;
@warningBoxShadow: none;

@placeholderWarningColor: lighten(@warningColor, 40);
@placeholderWarningFocusColor: lighten(@warningColor, 30);

/* Disabled */
@disabledColor: @solidSelectedBorderColor;

/* Primary */
@primaryBorderColor: var(--mainColour);
@primaryBackgroundColor: #f2f2f2;
