/*******************************
         Site Overrides
*******************************/
body {
  background: @bodyColor;
}

a:hover {
  color: inherit;
}

a:not([role="button"]):hover {
  color: @linkHoverColor;
}
