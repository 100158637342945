.no-wrap {
  white-space: nowrap;
}

.pre-line {
  white-space: pre-line;
}

.clearboth {
  clear: both;
}
