@vinMinWidth: 300px;
@vinHeight: 39px;
@vinFontSize: 20px;
@vinBorderRadius: 5px;
@vinBorderWidth: 2px;

.ui.input.vin_input {
  height: 38px;
}

.vin_input {
  display: inline-flex;
  height: @vinHeight;
  border-color: black;
  border-radius: @vinBorderRadius;
  border-width: @vinBorderWidth;
  border-style: solid;
  background-color: #fff;
  color: #121212;
  font-family: sans-serif;
  min-width: @vinMinWidth;
  white-space: nowrap;
  flex: 1 1 auto;
  align-self: center;
  font-size: @vinFontSize;
  text-align: center;
  text-transform: uppercase !important;
  overflow: hidden;

  input {
    padding: 0 !important;
    border: none !important;
    background: transparent !important;
    font-size: @vinFontSize;
    text-align: center !important;
    min-width: @vinMinWidth !important;

    &:focus {
      border: none;
      background: transparent;
    }
  }
}
