.logo {
  max-height: 100px !important;
  max-width: 70% !important;
  margin: auto !important;
  pointer-events: none;

  &.logo-lkq {
    max-width: 90%;
    path {
      fill: @lkq !important;
    }
  }

}
