// Indent child lines under their parent

@quickcreate-sidebar-width: 290px;
@textColor: rgba(0, 0, 0, 1);

.ui.table td.breaking {
  word-wrap: break-word;
}

.description-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: nowrap;
  div:first-child {
    flex-grow: 1;
  }
}

.workorder-table {
  // reserve space for the button, if it has one
  .product-description .description-container > div:first-child:not(:only-child) {
    max-width: calc(100% - 70px);
  }

  &.ui.celled.table tbody tr.parent-line-sub {
    td {
      padding-top: 1px;
      border-top: 0;
    }

    td.product-code,
    td.product-description {
      padding-left: 20px;
      font-style: italic;
    }

    td.has-summed-parent {
      font-style: italic;
    }

    td.has-summed-parent::before {
      content: '(';
    }

    td.has-summed-parent::after {
      content: ')';
    }
  }

  & tr.parent-line-sub-sub {
    td.product-description .description-container {
      padding-left: 10px;
    }

    td.product-code {
      padding-left: 10px;

      &:before {
        content: '└ ';
      }
    }
  }

  &.ui.celled.table tbody tr {
    td {
      padding: 11px 11px 1px 11px;
    }
  }

  &.ui.celled.table tbody tr:last-of-type {
    td {
      padding: 11px;
    }
  }

  // Draw cell borders if no child lines are present
  &.ui.celled.table tbody {
    td {
      border-top: 1px solid rgba(34, 36, 38, 0.1);
    }
  }

  &.ui.celled.table tbody:first-of-type {
    td {
      border-top: none;
    }
  }
}

/* Vehicle attributes */
.product-list {
  // This override is because the dropdowns on the product list fall under the table header, and there doesn't seem to be any reason
  // to have it at a high z-index here in the first place
  .cbgms-grid.table th {
    z-index: 0;
  }

  &__vehicle-attributes {
    h3 > .icon {
      cursor: pointer;
      font-size: 0.75em;
    }

    .ui.form .fields {
      flex-wrap: wrap;
    }

    .ui.image {
      width: 100px;
      vertical-align: top;
      display: inline-block;
    }
  }

  &__attributes-list {
    width: calc(100% - 100px);
    display: inline-block;

    label {
      display: inline-block;
    }
  }
}

/* Product filter */
.product-filter-menu {
  .ui.header {
    text-transform: capitalize;
  }

  .ui.checkbox {
    max-width: 80%;

    label {
      font-size: 0.857rem;
    }
  }

  .filter-item-amount {
    font-size: 0.857rem;
    color: lighten(@textColor, 70%);
  }
}

.product-filter-menu_header {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  margin: -0.5em;
  align-items: flex-end;

  .product-filter-menu_header-text {
    flex: 2 0 auto !important;
    margin: 0.5em;
  }

  .product-filter-menu_header-image {
    flex: 1 0 auto !important;
    margin: 0.5em;
  }
}

.product-filter_image-svg {
  max-height: 450px;

  /* center the svg without having to override semantic ui */
  margin-left: 50%;
  transform: translateX(-50%);

  .product-filter_image-click-region {
    stroke-linecap: round;
    stroke-linejoin: round;
    stroke-width: 0;
    fill-opacity: 0;
    transition: all 0.15s ease;
    cursor: pointer;
    &:hover,
    &:focus,
    &:active,
    &.active {
      fill: var(--mainColour);
      fill-opacity: 0.08;
      stroke: var(--mainColour);
      stroke-width: 3;
    }

    &.unknown {
      cursor: not-allowed;
      &:hover {
        fill: darkred;
        fill-opacity: 0.08;
        stroke: darkred;
        stroke-width: 3;
      }
    }
  }
}

.repairTimes {
  column-count: 3;

  .onTablets({column-count: 1;});

  .onSmallPc({column-count: 2});
}

.greyscale {
  filter: grayscale(90%);
}

.show-on-hover {
  opacity: 0;
  transition: 0.13s opacity;

  &:hover {
    transition: 0.25s opacity ease-in;
    opacity: 1;
  }
}

.checkbox__input-label {
  float: right;

  + .field {
    display: inline;
  }
}

.workorder-totals {
  display: inline-flex;
  flex-direction: column;

  .totals-label-shaded {
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
  }

  .totals-label-highlighted {
    margin: 0 !important;
    border-top-right-radius: 0;
    border-top-left-radius: 0;
    border-top: 0 !important;
  }
}

.textbutton {
  &:hover {
    text-decoration: underline;
  }
}

.workorder-header {
  display: flex;
  justify-content: space-between;

  .onSmallPc({flex-wrap: wrap});

  .quick-add-line-container {
    display: flex;
    align-items: center;
    min-width: 210px;
    flex-grow: 4;
    justify-content: flex-start;
    height: 28px;

    .ui.fluid.input > input {
      height: 28px !important;
    }

    .onSmallPc({justify-content: space-between; margin-bottom: 0.25em;});
  }

  .zindex1 {
    z-index: 1 !important;
  }

  .workorder-buttons-container {
    display: flex;
    flex-grow: 1;
    justify-content: flex-end;
    height: 28px;

    .onSmallPc({justify-content: flex-start; margin-top: 10px;});

    .dropdown-menu-ui {
      line-height: 0 !important;

      .dropdown-menu-label {
        display: table-cell;
        vertical-align: middle;
      }
    }

    .dropdown > div.text,
    .workorder-button {
      text-transform: inherit !important;
      font-weight: normal !important;
      font-size: 1rem !important;
    }
  }
}

.workorder-title__container {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.workorder-title__nav {
  margin-left: 1rem;
}

.workorder-title__icon {
  &:hover {
    cursor: pointer;
  }
}

.workorder-title__wholesaler-netprice-toggle {
  display: flex;
  align-items: center;
  padding-right: 2rem;

  .checkbox {
    padding: 0.125rem 0 0 1rem;
  }
}

.workorder-button {
  & > .floating.label {
    z-index: 1 !important;
  }
}

.vertical-align-baseline {
  vertical-align: baseline !important;
}

/* Workorder Activities */

.workorder-activities {
  &__list-item {
    white-space: nowrap;

    .content {
      overflow: hidden;
      max-width: 100%;
      text-overflow: ellipsis;
    }
  }
}

/* Create Workorder Quicklyy */

.create-workorder-quickly__container {
  display: flex;
}

.create-workorder-quickly__sidebar--fixed {
  display: flex;
  order: -1;
  flex: 0 0 @quickcreate-sidebar-width;
}

.create-workorder-quickly__sidebar-cards {
  .ui.card:first-child {
    margin-top: 1rem !important;
  }

  .ui.card {
    margin-top: 0 !important;
    margin-bottom: 1rem !important;
    margin-left: 0;
    margin-right: 0;
  }
}

.create-workorder-quickly__sidebar {
  width: @quickcreate-sidebar-width;
}

.create-workorder-quickly__sidebar-search {
  display: flex;
  align-items: center;
  margin-bottom: 1rem !important;

  .info.icon.circle {
    margin-left: auto;
  }
}

.create-workorder-quickly__custom-grid {
  order: 1;
  width: calc(100% - @quickcreate-sidebar-width) !important;
  flex-direction: column !important;
  margin-right: 0 !important;
  margin-left: 0 !important;

  .row .wide.column {
    padding-left: 1rem;
    padding-right: 0;
  }
}

.create-workorder-quickly__edit-vehicle-container,
.create-workorder-quickly__edit-account-container {
  display: flex;
  align-items: center;
  flex-direction: row;

  h5 {
    flex-grow: 3;
    margin-top: 0 !important;
    margin-bottom: 0 !important;
  }

  i.icon.pencil.small {
    margin-top: 0 !important;
    margin-right: 0 !important;

    &:hover {
      cursor: pointer;
      color: var(--mainColour) !important;
    }
  }
}

.vehicleinfo__dropdown {
  background-color: #f0f0f0;
  border-bottom: 1px solid var(--mainColour);
  border-radius: 3px;
}

.vehicleinfo__dropdown--container {
  .menu {
    .selected.item,
    .selected.item.active {
      font-weight: normal;
    }
  }
}

.odometer__dropdown {
  font-size: 0.8rem !important;
}

.add-parts-menu:hover {
  cursor: pointer;
  background-color: rgba(0, 0, 0, 0.03) !important;
}

.app-bar-card {
  position: fixed;
  bottom: 0;
  right: 0;
  z-index: 1000;
  left: 67px;
  box-shadow: 0 -2px 4px rgba(0, 0, 0, 0.1); /* Optional: Add a shadow for better visibility */
  &.side-bar-open {
    left: 228px;
  }
  transition: all 0.3s ease;
}

.app-box {
  padding-bottom: 4.25rem;
  &.empty {
    padding-bottom: 0;
  }
}

.work-txt {
  font-size: 1.75rem;
  &.primary {
    color: #005596;
  }
  &.small {
    font-size: 0.75rem;
  }
  &.medium {
    font-size: 1.25rem;
  }
  &.large {
    font-size: 1.35rem;
    font-weight: bold;
  }
}

.app-card {
  padding-bottom: 8px !important;
  padding-top: 8px;
}

.dotted-line {
  border: 0;
  border-top: '1px dotted';
  border-color: '#aaa';
  margin: '20px 0';
}
