.ButtonGroup-no-divider {
    // Remove the border-right between grouped buttons in default state
    & .MuiButtonGroup-grouped:not(:last-of-type) {
      border-right: none; // Remove the divider between buttons
    }
  
    // Remove the border-right on hover
    & .MuiButtonGroup-grouped {
      &:hover {
        border-right: none; // Ensure no divider appears on hover
      }
    }
  }