/*******************************
         Site Overrides
*******************************/

.ui.label {
  color: @labelColor;
}

.ui.label.circular {
  padding: @verticalPadding @horizontalPadding !important;
}

.ui.floating.label {
  z-index: 9;
}

.ui.label.floating.messages__amount {
  top: 0.75em;
  left: 5em;
  font-size: 0.55rem;
}

