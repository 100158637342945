@spacer: 7px;


.p0 {
  padding: 0 !important;
}

.p1 {
  padding: @spacer !important;
}

.p2 {
  padding: @spacer * 2 !important;
}

.p3 {
  padding: @spacer * 3 !important;
}

.p4 {
  padding: @spacer * 4 !important;
}

.py0 {
  padding-top: 0 !important;
  padding-bottom: 0 !important;
}

.py1 {
  padding-top: @spacer !important;
  padding-bottom: @spacer !important;
}

.py2 {
  padding-top: @spacer * 2 !important;
  padding-bottom: @spacer * 2 !important;
}

.py3 {
  padding-top: @spacer * 3 !important;
  padding-bottom: @spacer * 3 !important;
}

.py4 {
  padding-top: @spacer * 4 !important;
  padding-bottom: @spacer * 4 !important;
}

.px0 {
  padding-right: 0 !important;
  padding-left: 0 !important;
}

.px1 {
  padding-right: @spacer * 1 !important;
  padding-left: @spacer * 1 !important;
}

.px2 {
  padding-right: @spacer * 2 !important;
  padding-left: @spacer * 2 !important;
}

.px3 {
  padding-right: @spacer * 3 !important;
  padding-left: @spacer * 3 !important;
}

.px4 {
  padding-right: @spacer * 4 !important;
  padding-left: @spacer * 4 !important;
}

.pt0 {
  padding-top: 0 !important;
}

.pt02 {
  padding-top: @spacer * .2 !important;
}

.pt1 {
  padding-top: @spacer * 1 !important;
}

.pt2 {
  padding-top: @spacer * 2 !important;
}

.pt3 {
  padding-top: @spacer * 3 !important;
}

.pt4 {
  padding-top: @spacer * 4 !important;
}

.pl0 {
  padding-left: 0 !important;
}
.plhalf{
  padding-left: 0.5rem  !important;
}
.pl1 {
  padding-left: @spacer * 1 !important;
}

.pl2 {
  padding-left: @spacer * 2 !important;
}

.pl3 {
  padding-left: @spacer * 3 !important;
}

.pl4 {
  padding-left: @spacer * 4 !important;
}

.pr0 {
  padding-right: 0 !important;
}

.pr1 {
  padding-right: @spacer * 1 !important;
}

.pr2 {
  padding-right: @spacer * 2 !important;
}

.pr3 {
  padding-right: @spacer * 3 !important;
}

.pr4 {
  padding-right: @spacer * 4 !important;
}

.pb0 {
  padding-bottom: 0 !important;
}

.pb02 {
  padding-bottom: @spacer * .2 !important;
}

.pb1 {
  padding-bottom: @spacer * 1 !important;
}

.pb2 {
  padding-bottom: @spacer * 2 !important;
}

.pb3 {
  padding-bottom: @spacer * 3 !important;
}

.pb4 {
  padding-bottom: @spacer * 4 !important;
}
