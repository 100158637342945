// ROBOTO
// Add roboto as local font, We cannot use google fonts, gdpr in germany
@font-face {
  font-family: 'Roboto';
  src: url('./fonts/Roboto-Regular.woff') format('woff');
  src: url('./fonts/Roboto-Regular.woff2') format('woff2'),
  url('./fonts/Roboto-Regular.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: Roboto;
  src: url(./fonts/Roboto-Light.woff2) format("woff2"), url(fonts/Roboto-Light.woff) format("woff");
  /* Pretty Modern Browsers */
  font-weight: 300; }

@font-face {
  font-family: Roboto;
  src: url(./fonts/Roboto-Medium.woff2) format("woff2"), url(fonts/Roboto-Medium.woff) format("woff");
  /* Pretty Modern Browsers */
  font-weight: 500; }

@font-face {
  font-family: Roboto;
  src: url(./fonts/Roboto-Bold.woff2) format("woff2"), url(fonts/Roboto-Bold.woff) format("woff");
  /* Pretty Modern Browsers */
  font-weight: 700; }


// VIN FONT
@font-face {
  font-family: 'vin';
  src: url('./fonts/vin.eot');
  src: url('./fonts/vin.eot?#iefix') format('embedded-opentype'),
  url('./fonts/vin.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}

// LICENSE PLATE FONTS
@font-face {
  font-family: "kentekenregular";
  font-style: normal;
  font-weight: normal;
  src: url('./fonts/kentekenNL-normal.eot');
  src: url('./fonts/kentekenNL-normal.eot?#iefix') format("embedded-opentype"),
  url('./fonts/kentekenNL-normal.woff2') format('woff2'),
  url('./fonts/kentekenNL-normal.woff') format('woff'),
  url('./fonts/kentekenNL-normal.ttf') format("truetype"),
  url('./fonts/kentekenNL-normal.svg#font-family:kentekennlregular;') format("svg");
}

@font-face {
  font-family: 'kentekenbe';
  src: url('./fonts/kentekenBE-normal.eot');
  src: url('./fonts/kentekenBE-normal.eot?#iefix') format('embedded-opentype'),
  url('./fonts/kentekenBE-normal.woff2') format('woff2'),
  url('./fonts/kentekenBE-normal.woff') format('woff'),
  url('./fonts/kentekenBE-normal.ttf') format('truetype'),
  url('./fonts/kentekenBE-normal.svg#kentekenbenormal') format('svg');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'kentekenfr';
  src: url('./fonts/kentekenFR-normal.eot');
  src: url('./fonts/kentekenFR-normal.eot?#iefix') format('embedded-opentype'),
  url('./fonts/kentekenFR-normal.woff2') format('woff2'),
  url('./fonts/kentekenFR-normal.woff') format('woff'),
  url('./fonts/kentekenFR-normal.ttf') format('truetype'),
  url('./fonts/kentekenFR-normal.svg#kentekenfrregular') format('svg');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'kentekende';
  src: url('./fonts/kentekenDE-normal.eot');
  src: url('./fonts/kentekenDE-normal.eot?#iefix') format('embedded-opentype'),
  url('./fonts/kentekenDE-normal.woff2') format('woff2'),
  url('./fonts/kentekenDE-normal.woff') format('woff'),
  url('./fonts/kentekenDE-normal.ttf') format('truetype'),
  url('./fonts/kentekenDE-normal.svg#kentekenderegular') format('svg');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'kentekencz';
  src: url('./fonts/kentekenCZ-normal.eot');
  src: url('./fonts/kentekenCZ-normal.eot?#iefix') format('embedded-opentype'),
  url('./fonts/kentekenCZ-normal.woff2') format('woff2'),
  url('./fonts/kentekenCZ-normal.woff') format('woff'),
  url('./fonts/kentekenCZ-normal.ttf') format('truetype'),
  url('./fonts/kentekenCZ-normal.svg#kentekencznormal') format('svg');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'kentekenit';
  src: url('./fonts/kentekenIT-normal.eot');
  src: url('./fonts/kentekenIT-normal.eot?#iefix') format('embedded-opentype'),
  url('./fonts/kentekenIT-normal.woff2') format('woff2'),
  url('./fonts/kentekenIT-normal.woff') format('woff'),
  url('./fonts/kentekenIT-normal.ttf') format('truetype'),
  url('./fonts/kentekenIT-normal.svg#kentekenitregular') format('svg');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'kentekenuk';
  src: url('./fonts/kentekenUK-normal.eot');
  src: url('./fonts/kentekenUK-normal.eot?#iefix') format('embedded-opentype'),
  url('./fonts/kentekenUK-normal.woff2') format('woff2'),
  url('./fonts/kentekenUK-normal.woff') format('woff'),
  url('./fonts/kentekenUK-normal.ttf') format('truetype'),
  url('./fonts/kentekenUK-normal.svg#kentekenukregular') format('svg');
  font-weight: normal;
  font-style: normal;
}

// MAIN FONTS
@font-face {
  font-family: 'Ubuntu';
  src: url('./fonts/Ubuntu.eot');
  src: url('./fonts/Ubuntu.eot?#iefix') format('embedded-opentype'),
  url('./fonts/Ubuntu.woff') format('woff'),
  url('./fonts/Ubuntu.ttf') format('truetype'),
  url('./fonts/Ubuntu.svg') format('svg');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'UbuntuItalic';
  src: url('./fonts/Ubuntu-Italic.eot');
  src: url('./fonts/Ubuntu-Italic.eot?#iefix') format('embedded-opentype'),
  url('./fonts/Ubuntu-Italic.woff') format('woff'),
  url('./fonts/Ubuntu-Italic.ttf') format('truetype'),
  url('./fonts/Ubuntu-Italic.svg') format('svg');
  font-weight: bold;
  font-style: normal;
}

@font-face {
  font-family: 'UbuntuBold';
  src: url('./fonts/Ubuntu-Bold.eot');
  src: url('./fonts/Ubuntu-Bold.eot?#iefix') format('embedded-opentype'),
  url('./fonts/Ubuntu-Bold.woff') format('woff'),
  url('./fonts/Ubuntu-Bold.ttf') format('truetype'),
  url('./fonts/Ubuntu-Bold.svg') format('svg');
  font-weight: bold;
  font-style: normal;
}

@font-face {
  font-family: 'UbuntuBoldItalic';
  src: url('./fonts/Ubuntu-BoldItalic.eot');
  src: url('./fonts/Ubuntu-BoldItalic.eot?#iefix') format('embedded-opentype'),
  url('./fonts/Ubuntu-BoldItalic.woff') format('woff'),
  url('./fonts/Ubuntu-BoldItalic.ttf') format('truetype'),
  url('./fonts/Ubuntu-BoldItalic.svg') format('svg');
  font-weight: bold;
  font-style: normal;
}

@font-face {
  font-family: 'EmojiFlag';
  src: url('./fonts/TwemojiCountryFlags.woff2') format('woff2');
  font-style: normal;
}
