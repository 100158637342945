@red : #DB2828;
@orange : #F2711C;
@blue : #2185D0;

.messages--pointer{
  cursor: pointer;
}

.messages {
  width: 350px;
  display: flex;
  flex-direction: column;
  max-height: 650px;
  overflow-y: auto;
  .messages__single {
    padding: 0.5rem 1rem;
    font-weight: 500;
    background-color: #f0f8ff;
    &:hover {
      background-color: lighten(@grey, 35%);
      cursor: pointer;
    }
    .messages__single-header {
      display: flex;
      justify-content: space-between;
      padding-bottom: 0.25rem;
      border-bottom: 1px solid rgb(236, 230, 230);

      .messages__single-title--container {
        display: inline-flex;
        .messages__single-title--ellipsis {
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
          max-width: 190px;
        }
      }
      .messages__single-date {
        white-space: nowrap;
      }
    }
    .messages__single-body {
      display: flex;
      justify-content: space-between;
      padding:1.5rem 0;
      .messages__single-content {
        max-height: 65px;
        //line-height: 1.8em;
        -webkit-line-clamp: 4;
        -webkit-box-orient: vertical;
        overflow: hidden;
      }
      .messages__single-body-actions {
        text-align: right;
        white-space: nowrap;
        .messages__single-body-actions-delete {
          visibility: hidden;
        }
      }
      &:hover {
        .messages__single-body-actions-delete {
          visibility: visible;
        }
      }
    }
  }
  .messages__single--read {
    background-color: #fff;
    h4 {
      font-weight: normal !important;
    }
    font-weight: normal !important;
  }

  .messages__single-icon--info {
    color: @blue;
  }
  .messages__single-icon--warning {
    color: @orange;
  }
  .messages__single-icon--remove {
    color: @red;
  }
}
