.main-search-popup-portal {
  input {
    height: 35px;
  }
}

.main-search-filter-dropdown {
  transform: translateX(-100%);
  color: #444;
  z-index: 998;
  height: 32px;
  border-bottom-right-radius: 4px;
  border-top-right-radius: 4px;
  background-color: #eee;
  display: flex !important;
  align-items: center;
}
