@LargeBreakpoint: 1499px;
@LargeBreakpoint: 1499px;
@MediumBreakpoint: 1023px;

.dashboard__table-widget {
  overflow-x: scroll;
  overflow-y: scroll;
  max-height: 350px;
}

.dashboard__search {
  margin-top: 1rem;
}

@media screen and (max-width: 1700px) {
  .dashboard__time-registration {
    .two.column.row .column {
      margin-top: 1rem;
      width: 100% !important;
    }
  }
}

.card.unstyled {
  background: none !important;
  box-shadow: none !important;
  border: 0 !important;
}

.dashboard_wholesalerimage {
  height: 37px !important;
}

.search-result_container,
.ui.segment.search-result_container {
  margin-top: 0;
  margin-left: 0.5rem;
  margin-right: 0.5rem;
  position: absolute;
  border-top: none;
  border-radius: 0;
  left: 0;
  right: 0;
  z-index: 99;
}

.dashboard_portalContainers {
  display: flex;
  justify-content: stretch;
  align-content: stretch;
  margin: -1rem;

  >div {
    flex: 1 1 0px;

    >div {
      flex: 1 1 0px;
      margin: 1rem;
    }
  }
}

.dashboard_columnContainer {
  column-count: 2;
  column-gap: 1rem;
  margin: -0.5rem;

  &:first-child {

    /* this hack will cause the columns to break properly, otherwise it will only break after 3 or more elements*/
    &::before {
      content: ' ';
      display: inline-block;
      break-inside: avoid;
      page-break-inside: avoid;
      break-after: column;
    }
  }

  >div {
    >div {
      &:first-child {
        margin-top: -0.9rem !important;
      }

      display: inline-block;
      break-inside: avoid;
      page-break-inside: avoid;
      break-after: column;
      width: 100%;
      margin: 0.5rem !important;
    }
  }

  @media (max-width: @LargeBreakpoint) {
    column-count: 1;
  }
}

.dashboard_portalContainers {
  @media (max-width: @MediumBreakpoint) {
    flex-direction: column;
  }
}

.dashboard_portalContainers {
  .dashboard_cmsContainer {
    max-width: 29.9vw;
    min-width: 23vw;

    @media (max-width: @MediumBreakpoint) {
      max-width: 100%;
      min-width: 100%;
    }
  }

  .dashboard_timeRegistrationContainer {
    margin: 0.5rem !important;
  }
}

.card-txt {
  color: #0288d1;
  font-size: 3rem;
  margin: 0;
  font-weight: 600;

  &.txt-med {
    font-size: 1rem;
    color: #01579b;
  }
}

.widget-card {
  min-width: 48%;
  //margin: 16px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  background-color: #e9f5fe;
  padding: 0 16 16 0;
  box-sizing: border-box;
  cursor: pointer;
  max-height: 150px;
  margin-left: 0;

  .widget-box {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .widget-card-content {
    display: flex;
    width: 95%;
    padding-left: 0;
  }
}

.dashboard-open-request-box {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-wrap: nowrap;
  flex-direction: row;
  justify-content: space-between;
  padding-top: 16px;
}

//update and approve css
.update-approve-dialog {
  .dialog-action-button {
    padding-top: 1rem;
    border-top: 0.5px solid lightgray;
  }
}

.update-approve-form-group {
  padding-top: 1rem;
  padding-bottom: 1rem;

  .form-field {
    color: black;
  }

  .previous-value {
    color: red;
  }

  .updated-value {
    color: darkgreen;
  }

}
