.search-result .description {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  align-items: flex-start;
}

.search-result-content {
  flex: 1;
}

.search-item-with-buttons .result:hover {
  /* I'm sorry, I didn't want to this, but the specificity is too damn high! I cannot realistically compete with a specificity of 0 5 0 */
  cursor: auto !important;
  background-color: initial !important;
}
