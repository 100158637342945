/*******************************
         Site Overrides
*******************************/

.ui.modal {
  & > .header {
    color: @textColor;
  }
  &.tabbed {
    .header {
      border-bottom: 0;
    }

    .content {
      padding-top: 0;
      padding-left: 0;
      padding-right: 0;

      .form {
        .bottom {
          border-bottom: 0;
        }

        .tabular.menu {
          min-height: 0;

          .item:first-child:nth-last-child(2) {
            // hides the tabs once we only have one tab
            display: none;
            & + .right.menu {
              display: none;
            }
          }
        }
      }
    }
    .actions {
      .btn-delete {
        margin-right: auto !important;
      }
    }
  }
}
