// SOME FIXES FOR IE 11
@media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
  .ui.vertical.menu.fluid {
    width: 260px !important;
    padding-top: 35px !important;
  }

  .ui.menu.icon.fluid,
  .ui.vertical.menu.icon.fluid {
    width: 60px !important;
  }
}
