.my-workorder {
  &__block {
    display: flex;
    flex-direction: column;
    border-radius: 0 !important;
    border-bottom: 2px solid var(--mainColour) !important;
    box-shadow: none !important;

    &__shop-logo {
      max-height: 7em;
      max-width: 100%;
    }
  }

  .block-workorder-table {
    flex-grow: 1;
    overflow: auto;
    &__icon {
      &--blue {
        color: blue !important;
      }
      &--red {
        color: red !important;
      }
      &--green {
        color: green !important;
      }
      &--lock {
        font-size: 0.55em !important;
      }
    }
    tbody:nth-child(even) td {
      background: @hoverColor !important;
    }
    tbody {
      td {
        padding-left: 11px !important;
        padding-right: 11px !important;
        border-top: none;
      }
    }
  }
  .block-totals {
    td {
      border: none !important;
      padding: 0.5rem 0 !important;
    }
    &__table-totals {
      font-weight: bold;
      font-size: 1.08rem;
    }
  }

  .my-workorder__block:not(.block-workorder) {
    flex-grow: 0 !important;
  }
}

.block-footer {
  float: right;
  padding-right: 1rem;

  &__logo {
    height: 25px;
  }
  &__powered-by {
    display: flex;
    font-size: 0.7rem;
  }
}

@media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
  .block-footer {
    margin-right: 40px;
  }

  .my-workorder {
    .block-car-info__brandinfo {
      .licenseplate {
        min-width: 150px;
      }
    }
  }
}

// This overrides the default semantic container
@media only screen and (min-width: 768px) and (max-width: 990px) {
  .my-workorder {
    .ui.container {
      width: calc(100vw - 10rem);
    }
    .ui.segment {
      margin-top: 0;
      margin-bottom: 1rem;
    }
    .ui.container.fluid {
      width: 100vw !important;
    }
  }
}

// Screensize between 991px and 1112px
@media only screen and (min-width: 998px) {
  .my-workorder {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    min-height: 100vh;

    &__wrapper {
      flex-grow: 1 !important;
    }

    .ui.container {
      width: calc(100vw - 3rem);
    }
    .block-workorder-table {
      max-height: 500px;
    }
    .hidden-sm {
      display: none;
    }
  }
}

// Screensize > 1112px
@media only screen and (min-width: 1112px) {
  .my-workorder {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    min-height: 100vh;

    &__wrapper {
      flex-grow: 1 !important;
    }
    .ui.container {
      width: calc(100vw - 15rem);
    }
    .ui.container.fluid {
      width: 100vw !important;
    }
    .block-workorder-table {
      max-height: 500px;
    }
  }

  .hidden-sm {
    display: none;
  }
}

@media only screen and (max-width: 998px) {
  .hidden-mobile {
    display: none !important;
  }

  .my-workorder {
    .ui.segment {
      margin-bottom: 20px;
    }
    .block-totals {
      td {
        border: none !important;
      }
    }
  }
}
