// OVERRULE LICENSE PLATE INPUT
.licenseplate .ui.input input {
  background: none !important;
  border: none !important;
  color: #121212;
  height: auto !important;
}

.licenseplate .ui.input:hover input {
  border: none !important;
}
