.ui.label.theme-color {
  // Adds support for label in in the theme color
  background-color: var(--mainColour) !important;
  border-color: var(--mainColour) !important;
  color: var(--mainColourText) !important;

  &.ribbon {
    border-color: var(--mainDarkenedColour) !important;
  }
}

a:hover .ui.label.theme-color,
a.ui.label.theme-color:hover {
  background-color: var(--mainLightenedColour) !important;
  &.ribbon {
    border-color: var(--mainColour) !important;
  }
}
