.clickable {
  cursor: pointer;
}

.readOnly:hover,
input[readonly] {
  cursor: not-allowed;
}

.emphasize:hover {
  background-color: #f7f7f7;
}

.movable-h {
  cursor: ns-resize;
}

.draggable {
  cursor: move;
  cursor: -moz-grab;
  cursor: -webkit-grab;
}

.drop-target {
  filter: brightness(95%);
}

.drop-target-active {
  filter: brightness(90%);
}

/* IE11 fix since it doesn't support filters */
*::-ms-backdrop, .drop-target-active {
  opacity: 0.85;
}

/* IE11 fix since it doesn't support filters */
*::-ms-backdrop, .drop-target {
  opacity: 0.4;
}

