@spacer: 7px;

@mobileBreakpoint: 320px;
@tabletBreakpoint: 768px;
@computerBreakpoint: 992px;
@largeMonitorBreakpoint: 1200px;
@widescreenMonitorBreakpoint: 1920px;

// COMPANY COLORS
@lkq: #005596;
@lkqMainColorText: #fff;

:root {
  --mainColour: @lkq;
  --offsetColour: spin(@lkq, -40);
  --mainDarkenedColour: darken(@lkq, 10);
  --mainLightenedColour: lighten(@lkq, 10);
  --mainVeryLightenedColour: lighten(@lkq, 55);
  --mainSuperLightenedColour: lighten(@lkq, 68);
  --mainColourText: @lkqMainColorText;
}

// MAIN COLORS
@regularTextColor: #727171;
@hoverColor: #f3f3f3;
@placeholderColor: rgba(255, 207, 0, 0.50);
@inputBgColor: #ededed;
@inputBorderColor: #727171;
@inputErrorBgColor: #fce7de;
@inputErrorBorderColor: #e43632;
@dropdownSelectionColor: #e0e1e2;
@dropdownSelectionTextColor: #676768;
@dropdownSelectionHoverColor: #cacbcd;
@dropdownSelectionHoverTextColor: #383939;
@white: #fff;
@bodyColor: #f0f0f0;
@buttonHoverColor: rgba(0, 0, 0, 0.8);
@buttonBackgroundHoverColor: rgba(0, 0, 0, 0.03);
@linkColor: #444444;
@linkHoverColor: #121212;

// BORDERS
@borderRadius: none;
@borderColor: rgba(34, 36, 38, 0.15);

/*---   Neutrals  ---*/
@fullBlack : #000000;
@offWhite : #F3F3F4;

// mixin's
.onTablets(@rules) {
  @media (max-width: @tabletBreakpoint) {
    @rules();
  }
}

.onTinyPc(@rules) {
  @media (max-width: @computerBreakpoint) {
    @rules();
  }
}

.onSmallPc(@rules) {
  @media (min-width: @tabletBreakpoint) and (max-width: @largeMonitorBreakpoint) {
    @rules();
  }
}

.betweenTabletAndComputer(@rules) {
  @media (min-width: @tabletBreakpoint) and (max-width: @computerBreakpoint) {
    @rules();
  }
}

.onMobile(@rules) {
  @media (min-width: @mobileBreakpoint) and (max-width: @tabletBreakpoint) {
    @rules();
  }
}

.onLargePc(@rules) {
  @media (min-width: @largeMonitorBreakpoint) {
    @rules();
  }
}
