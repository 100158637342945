.cbgms-dnd-target {
  border: 1px solid @inputBorderColor;
  padding: 2rem;
  display: inline-block;
  text-align: center;
  min-width: 25ch;
  position: relative;
  transition: all .2s;

  .icon {
    transition: all .2s;
  }

  &--disabled, &--loading {
    pointer-events: none;
    opacity: .5;
  }

  &--active, &:hover {
    border: 1px solid var(--mainColour);
    cursor: pointer;

    .icon {
      color: var(--mainColour)
    }
  }

  &--loading {
    .icon {
      opacity: 0;
    }
  }
}