@listitem-hover: #fafafa;

.list-icon-group {
  i {
    width: 1.18em !important;
    height: 1em !important;
    padding: 0 !important;
    margin-right: -0.5rem !important;
  }
}

.changelog-list {
  padding: .5rem !important;
  border: 1px solid #D4D4D5;
  border-top-right-radius: 0.28571429rem;
  border-top-left-radius: 0.28571429rem;
  margin-bottom: 0 !important;
  overflow-y: auto;
  max-height: 30rem;

  .description {
    padding-top: 7px;
  }

  a {
    .licenseplate {
      margin-left: .7rem;
      position: relative;
      top: -3px;
    }
    i.bordered.icon {
      border-radius: 3px;
    }
    i.vehicle.brand {
      position: relative;
      top: 2px;
    }
  }
  a:hover {
    background-color: @listitem-hover;

    i.bordered.icon {
      border-radius: 3px;
      background-color: white;
    }
  }
}

.cms-messages {
  .item {
    cursor: pointer;
    .icon {
      color: var(--mainColour)
    }
    .content {
      line-height: 1.5rem !important;
    }
  }
  .item:hover {
    background-color: @listitem-hover;
  }
}
