/*******************************
         Site Overrides
*******************************/

.ui.basic.button.floating.dropdown,
.ui.multiple.dropdown > .label {
  background: @dropdownSelectionColor!important;
  color: @dropdownSelectionTextColor!important;
  border: none !important;
  font-weight: normal !important;
  -webkit-box-shadow: 0 0 0 1px @dropdownSelectionColor inset;
  -moz-box-shadow: 0 0 0 1px @dropdownSelectionColor inset;
  box-shadow: 0 0 0 1px @dropdownSelectionColor inset;

  &:hover {
    background: @dropdownSelectionHoverColor!important;
    color: @dropdownSelectionHoverTextColor!important;
    -webkit-box-shadow: 0 0 0 1px @dropdownSelectionHoverColor inset;
    -moz-box-shadow: 0 0 0 1px @dropdownSelectionHoverColor inset;
    box-shadow: 0 0 0 1px @dropdownSelectionHoverColor inset;
  }
}

.ui.button.floated.label {
  position: relative !important;
}
// Specialty class for the calendar

.ui.basic.buttons {
  div {
    .ui.icon.button {
      padding: @verticalPadding @verticalPadding (@verticalPadding + @shadowOffset);
      border-radius: 0 !important;
    }
  }
  div:first-child {
    border-left: none;
    margin-left: 0em;
    border-top-left-radius: @borderRadius;
    border-bottom-left-radius: @borderRadius;
  }
  div:last-child {
    border-left: none;
    margin-left: 0em;
    border-top-right-radius: @borderRadius;
    border-bottom-right-radius: @borderRadius;
    .button {
      border-top-left-radius: none !important;
      border-bottom-left-radius: none !important;
    }
  }
}

.ui.button.basic-dropdown-button.workorder-buttons.workorder-button {
  color: #444 !important;
}

.ui.attached.button.dropdown-attached {
  box-shadow: @boxShadow !important;
}
