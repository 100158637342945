@font-face {
  font-family: 'cbgms';
  src: url(~'../../fonts/cbgms.eot');
  src: url(~'../../fonts/cbgms.eot#iefix') format('embedded-opentype'),
  url(~'../../fonts/cbgms.ttf') format('truetype'),
  url(~'../../fonts/cbgms.woff') format('woff'),
  url(~'../../fonts/cbgms.svg#cbgms') format('svg');
  font-weight: normal;
  font-style: normal;
}

[class^="icon-"], [class*=" icon-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'cbgms' !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

}
i.disabled.icon.sort-toggle {
  opacity: 0.15 !important;
}

i.disabled.icon.sort-toggle:hover {
  opacity: 1 !important;
}


.icon-aa-team:before {
  content: "\e909";
}

.icon-accountview-full:before {
  content: "\e90a";
}

.icon-accountview:before {
  content: "\e90b";
}

.icon-barcode:before {
  content: "\e90c";
}

.icon-bicycle:before {
  content: "\e90d";
}

.icon-boris:before {
  content: "\e90e";
}

.icon-bosch-car-service:before {
  content: "\e90f";
}

.icon-bovag:before {
  content: "\e910";
}

.icon-car-crash:before {
  content: "\e911";
}

.icon-car-damage:before {
  content: "\e912";
}

.icon-car-lift:before {
  content: "\e913";
}

.icon-cardreader:before {
  content: "\e914";
}

.icon-coffee:before {
  content: "\e915";
}

.icon-exact-online:before {
  content: "\e916";
}

.icon-external-noise-tyre-label-0:before {
  content: "\e917";
}

.icon-external-noise-tyre-label-1:before {
  content: "\e918";
}

.icon-external-noise-tyre-label-2:before {
  content: "\e919";
}

.icon-external-noise-tyre-label-3:before {
  content: "\e91a";
}

.icon-external-noise-tyre-label:before {
  content: "\e91b";
}

.icon-fuel-efficiency-tyre-label:before {
  content: "\e91c";
}

.icon-mechanic2:before {
  content: "\e930";
}

.icon-rdw-add:before {
  content: "\e91d";
}

.icon-rdw-arrow-wide:before {
  content: "\e91e";
}

.icon-rdw-arrow:before {
  content: "\e91f";
}

.icon-rdw-dashboard:before {
  content: "\e920";
}

.icon-rdw-info:before {
  content: "\e921";
}

.icon-rdw-rv:before {
  content: "\e922";
}

.icon-rdw-wide:before {
  content: "\e923";
}

.icon-rdw:before {
  content: "\e924";
}

.icon-rob:before {
  content: "\e925";
}

.icon-twinfield-full:before {
  content: "\e926";
}

.icon-twinfield:before {
  content: "\e927";
}

.icon-tyre-all-season:before {
  content: "\e928";
}

.icon-tyre-decibel:before {
  content: "\e929";
}

.icon-tyre-search:before {
  content: "\e92a";
}

.icon-tyre-summer:before {
  content: "\e92b";
}

.icon-tyre-winter:before {
  content: "\e92c";
}

.icon-tyre:before {
  content: "\e931";
}

.icon-tyre2:before {
  content: "\e92d";
}

.icon-vakgarage:before {
  content: "\e92e";
}

.icon-wet-grip-tyre-label:before {
  content: "\e92f";
}

.icon-diary:before {
  content: "\e900";
}

.icon-dashboard:before {
  content: "\e901";
}

.icon-workorder:before {
  content: "\e902";
}

.icon-invoices:before {
  content: "\e903";
}

.icon-accounts:before {
  content: "\e904";
}

.icon-settings:before {
  content: "\e905";
}

.icon-vehicles:before {
  content: "\e906";
}

.icon-customer:before {
  content: "\e907";
}

.icon-construction-bolt:before {
  content: "\e932";
}
