.flex-inline {
  display: inline-flex !important;
}

.flex-box {
  display: flex !important;
}

.flex-column {
  flex-direction: column !important;
}

.flex-row {
  flex-direction: row !important;
}

.flex-pull-top {
  margin-bottom: auto !important;
}

.flex-pull-right {
  justify-content: flex-end !important;
  margin-left: auto !important;
}

.flex-pull-bottom {
  margin-top: auto !important;

  // Reset flex-pull-bottom for the next siblings (stops margin-auto from dividing among it's peers, instead apply all margin auto to the first child)
  & ~ .flex-pull-bottom {
    margin-top: 0 !important;
  }
}

.flex-pull-left {
  justify-content: flex-start !important;
  margin-right: auto !important;
}

.align-items-start {
  align-items: flex-start !important;
}

.align-items-end {
  align-items: flex-end !important;
}

.align-items-center {
  align-items: center !important;
}

.align-items-baseline {
  align-items: baseline !important;
}

.align-items-stretch {
  align-items: stretch !important;
}

.align-self-start {
  align-self: flex-start !important;
}

.align-self-end {
  align-self: flex-end !important;
}

.align-self-center {
  align-self: center !important;
}

.align-self-baseline {
  align-self: baseline !important;
}

.align-self-stretch {
  align-self: stretch !important;
}

.justify-content-start {
  justify-content: flex-start !important;
}

.justify-content-end {
  justify-content: flex-end !important;
}

.justify-content-center {
  justify-content: center !important;
}

.justify-content-between {
  justify-content: space-between !important;
}

.justify-content-around {
  justify-content: space-around !important;
}

.justify-content-space-evenly {
  justify-content: space-evenly !important;
}

.flex-wrap {
  flex-wrap: wrap;
}

.flex {
  flex: 1 0 auto !important;
}

.flex-2 {
  flex: 2 0 auto !important;
}

//noinspection CssRedundantUnit //IE 11 needs 0 to be 0px
.flex-no-basis {
  flex: 1 1 0px !important;
}

.flex-no-shrink {
  flex-shrink: 0 !important;
}

.flex-no-grow {
  flex-grow: 0 !important;
}

.verticle-align-middle {
  vertical-align: middle;
}

.flex-no-wrap {
  flex-wrap: nowrap;
}
