.cookie-consent__container {
  z-index: 99999999;
  position: fixed;
  display: flex;
  flex-wrap: nowrap;
  flex-direction: column;
  bottom: 1rem;
  right: 1rem;
  padding: 1.5rem;
  max-width: 24em;
  background: #1b1c1d;
  color: @white;
}

.cookie-consent__message {
  display: block;
  margin-bottom: 0.5rem;
  font-size: 1.2rem;
  line-height: 1.8rem;
  &--link {
    color: red;
    cursor: pointer;
    &:hover {
      color: #a8a8a8 !important;
    }
  }

  &--small {
    font-size: 1rem;
    .cookie-consent__policy-link {
      color: #a8a8a8 !important;
      &:hover {
        text-decoration: underline;
      }
    }
  }
}

.cookie-consent__message--link-hidden {
  display: none;
}

.cookie-consent__detailed {
  display: block;

  .fields > .checkbox > .cookie-consent__label {
    color: @white !important;
  }
  &.cookie-consent__detailed--hidden {
    display: none;
  }
}
.cookie-consent__buttons {
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex: 1 0 auto;
  &.cookie-consent__button {
    flex: 1 !important;
  }
  + .cookie-consent__button {
    margin-left: 1rem;
  }
}
