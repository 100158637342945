// EXTERNAL COMPANY COLORS
@rdwColor: #c30;

//
.color-rdw {
  color: @rdwColor;
}

.background-rdw {
  background-color: @rdwColor;
}

.icon-rdw-margin-big {
  // shrink the margins to fit the icon inside the button
  margin-top: -7px !important;
  margin-bottom: -4px !important;
}

.rdw-provider-card {
  .image {
    display: flex !important;
    padding: 10px !important;
    justify-content: center;
    align-content: center;
    align-items: center;
    min-height: 200px;
    img {
      height: initial !important;
      width: 100% !important;
      max-height: 180px !important;
    }
  }
}
