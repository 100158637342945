// SUMMARY
.summary-header {
  //border-top: 5px solid var(--mainColour) !important;
  padding: 0.5rem 1rem !important;
  margin-bottom:1.5rem !important;
  background-color:#f3f3f4 !important;
  min-height: 3rem;
}
.summary-header__account-list-icon {
  //position: absolute;
  //left: -1rem;
}

.summary-header__account-list {
  .summary-header__account-list-item {
    padding: 0.3em 0 !important;
    .content{
    line-height: 1.54285714em !important;
  }
  }

  .summary-header-list-buttons {
    color: #b2b1b1 !important;
    font-size: 12px !important;
    align-content: end !important;

    a {
      color: #b2b1b1 !important;
      font-size: 12px !important;
    }
  }
  .item {
    // padding: 0.3em 0 !important;
     line-height: 1.54285714em !important;
   }
}

.summary-header__invoice-remark {
  p {
    max-width: 300px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
}

.summary-subnav {
  min-height: 2.4rem;
  background-color: @offWhite;
  padding: 0.5rem 0 !important;
}

.summary-subnav__icon {
  color: var(--mainColour);
}

.summary-header-list-buttons {
  align-content: end !important;

  a {
    color: #b2b1b1 !important;
    font-size: 12px !important;
  }
}

.summary-header-account-list {

  &__info {

    > .item {
      padding-bottom: 5px !important;
      max-width: 175px !important;
      min-width: 160px !important;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }

  &__title {
    background-color: #e0e0e0;
    margin: 0rem !important;
    padding-top: 1rem !important;
    padding-bottom: 0.75rem !important;
  }
}
//position dropdown to the left on small screens
@media screen and(max-width:1280px) {
}

//position dropdown to the left on small screens
@media screen and(min-width:1281px) {
}
