.button-label {
  margin: -0.7em 0 -0.7em 1em !important;
  position: relative !important;
}

// Semantic labeled icon-button icon as 'a' height fix.
// Currently only used on the contact page (NVR: 29-08-2018)
.labeled-icon-button-lh-fix {
  line-height: 1.5em !important;
}

.main-col-text {
  color: var(--mainColour) !important;
}
.main-col-bg {
  background-color: var(--mainColour) !important;
}

.white-text {
  color: white !important;
}
.darkgrey-text {
  color: #a0a0a0 !important;
}

.float-r {
  float: right;
}
.float-l {
  float: left;
}

.hover-underline:hover {
  text-decoration: underline;
}
.cursor-pointer {
  cursor: pointer;
}
.cursor-alias {
  cursor: alias;
}

.custom-h1 {
  font-size: 1.1rem !important;
  font-weight: 400 !important;
}

.custom-h2 {
  font-size: 1rem !important;
  font-weight: 400 !important;
}

.custom-h3 {
  font-size: 1rem !important;
  font-weight: 300 !important;
}
