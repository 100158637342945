/*******************************
         Site Overrides
*******************************/

i.icons {
  margin-right: @distanceFromText;
}

.workorder-table i.icons .corner.icon.lock {
  font-size: 0.55em;
}
