.invoice-layout {
  &__container {
    margin-top: 4.83rem !important;
    padding-top: 0px !important;
    overflow: hidden;
    height: calc(198mm + 100px);

    .onSmallPc({
      height: calc(118mm + 80px);
    });

    .onTinyPc({
      margin-top: 0px !important;
    });
  }

  &__preview {
    width: 150%;
    height: calc(297mm + 40px);
    transform: scale(2/3);
    transform-origin: 0 0;
    position: relative;

    .onSmallPc({ width: 250%; transform: scale(0.4); });
  }

  &__image-preview {
    height: 100% !important; // IE11 Fix
    border: 5px solid @image-border-color;
  }
}

.invoice-preview {
  height: calc(297mm + 30px);
  width: 167%;
  transform-origin: 0 0;
  transform: scale(0.6);
  .onSmallPc({ height: 305mm; width: 200%; transform: scale(0.5); });
}
