.product_groups {
  .column {

    .header {
      display:block;
      font-size: 1.2em;
      padding: 5px 8px;
      border-color: var(--mainColour);
    }

    .list {
      padding: 0 8px;
      font-size: 1em;
      margin-bottom: 1rem;

      .item a:hover {
        color: var(--mainColour) !important;
      }
    }
  }
}

.product-list-item-image {
  text-align: center;

  i.icon {
    display: inline-block;
  }
}


.product-group-search-box input {
  padding-top:16.5px !important;
  padding-bottom:16.5px !important;
}
