// GRIDS & TABLES
.cbgms-grid.border,
.workorder-table,
.cbgms-table {
  border: none !important;
}

.cbgms-table{
  border:1px solid black !important;
}

.workorder-table:first-child {
 margin-top: 20px;
}

.cbgms-grid.table tr:nth-child(odd) td,
.cbgms-table tr:nth-child(odd) td {
  background: @hoverColor !important;
}

.workorder-table tbody:nth-child(even) td {
  background: @hoverColor !important;
}

.workorder-table tbody .parent-line-placeholder td {
  background: @placeholderColor !important;
}

.cbgms-grid.table,
.workorder-table,
.cbgms-table {

  th {
    border-bottom: 3px solid @inputBorderColor !important;
    background: #fff !important;

    &:last-of-type {
      border-right: none !important;
    }
  }

  td {
    background: none !important;
    border-top: 1px solid transparent !important;
    border-bottom: 1px solid transparent !important;
    padding: 5px 8px !important;

    &:last-of-type {
      border-right: none !important;
    }
  }

  tr {
    border-width: 1px;

    td:first-of-type {
      border-left: 1px solid transparent !important;
    }

    td:last-of-type {
      border-right: 1px solid transparent !important;
    }
  }

  tbody > tr:hover {
    td {
      border-top: 1px solid @inputBorderColor !important;
      border-bottom: 1px solid @inputBorderColor !important;
    }

    td:first-of-type {
      border-left: 1px solid @inputBorderColor !important;
    }

    td:last-of-type {
      border-right: 1px solid @inputBorderColor !important;
    }
  }
}

.cbgms-grid .ui.disabled.checkbox .box:after,
.cbgms-grid .ui.disabled.checkbox label,
.cbgms-grid .ui.checkbox input[disabled] ~ .box:after,
.cbgms-grid .ui.checkbox input[disabled] ~ label {
  opacity: 1 !important;
}
