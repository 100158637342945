.cbgms-progress-bar {
  background: linear-gradient(90deg, var(--mainColour), var(--offsetColour));
  height: 2rem;
  width: 100%;
  position: relative;
  border-radius: 2px;
}
.cbgms-progress-bar__step {
  &:first-child {
    border: 0;
  }
  width: 20%;
  border-left: 10px solid #fff;
  float: left;
  z-index: 1;
  position: relative;
  height: 100%;
}
.cbgms-progress-bar__incomplete {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  width: 0;
  background-color: #e1e9ee;
  border-radius: 1px;
  transition: width 1s cubic-bezier(0, 0, 0.2, 1) 0.5s;
  transform-origin: 100%;
  &--in-progress {
    border-radius: 0 1px 1px 0;
  }
}
.cbgms-progress-bar__check {
  position: absolute;
  bottom: -7.5px;
  z-index: 1;
  transition: right 1s cubic-bezier(0, 0, 0.2, 1) 0.5s;
}
