@spacer: 1rem;

.mt-auto {
  margin-top: auto !important;
}

.mr-auto {
  margin-right: auto !important;
}

.mb-auto {
  margin-bottom: auto !important;
}

.mx-auto {
  margin-right: auto !important;
  margin-left: auto !important;
}

.ml-auto {
  margin-left: auto !important;
}
.m-half {
  margin: @spacer / 2;
}
.m0 {
  margin: 0 !important;
}

.m1 {
  margin: @spacer !important;
}

.m2 {
  margin: @spacer * 2 !important;
}

.m3 {
  margin: @spacer * 3 !important;
}

.m4 {
  margin: @spacer * 4 !important;
}
.my-half {
  margin-top: @spacer / 2 !important;
  margin-bottom: @spacer / 2 !important;
}

.my0 {
  margin-top: 0 !important;
  margin-bottom: 0 !important;
}

.my1 {
  margin-top: @spacer !important;
  margin-bottom: @spacer !important;
}

.my2 {
  margin-top: @spacer * 2 !important;
  margin-bottom: @spacer * 2 !important;
}

.my3 {
  margin-top: @spacer * 3 !important;
  margin-bottom: @spacer * 3 !important;
}

.my4 {
  margin-top: @spacer * 4 !important;
  margin-bottom: @spacer * 4 !important;
}

.mx0 {
  margin-right: 0 !important;
  margin-left: 0 !important;
}

.mx1 {
  margin-right: @spacer * 1 !important;
  margin-left: @spacer * 1 !important;
}

.mx2 {
  margin-right: @spacer * 2 !important;
  margin-left: @spacer * 2 !important;
}

.mx3 {
  margin-right: @spacer * 3 !important;
  margin-left: @spacer * 3 !important;
}

.mx4 {
  margin-right: @spacer * 4 !important;
  margin-left: @spacer * 4 !important;
}

.mt0 {
  margin-top: 0 !important;
}

.mt1 {
  margin-top: @spacer * 1 !important;
}
.mt-half {
  margin-top: @spacer / 2 !important;
}

.mt2 {
  margin-top: @spacer * 2 !important;
}

.mt3 {
  margin-top: @spacer * 3 !important;
}

.mt4 {
  margin-top: @spacer * 4 !important;
}

.mr0 {
  margin-right: 0 !important;
}

.mr1 {
  margin-right: @spacer * 1 !important;
}

.mr2 {
  margin-right: @spacer * 2 !important;
}

.mr3 {
  margin-right: @spacer * 3 !important;
}

.mr4 {
  margin-right: @spacer * 4 !important;
}

.mb0 {
  margin-bottom: 0 !important;
}
.mb-half {
  margin-bottom: @spacer / 2 !important;
}
.mb1 {
  margin-bottom: @spacer * 1 !important;
}

.mb2 {
  margin-bottom: @spacer * 2 !important;
}

.mb3 {
  margin-bottom: @spacer * 3 !important;
}

.mb4 {
  margin-bottom: @spacer * 4 !important;
}

.ml-half {
  margin-left: @spacer / 2 !important;
}
.ml0 {
  margin-left: 0 !important;
}

.ml1 {
  margin-left: @spacer * 1 !important;
}

.ml2 {
  margin-left: @spacer * 2 !important;
}

.ml3 {
  margin-left: @spacer * 3 !important;
}

.ml4 {
  margin-left: @spacer * 4 !important;
}
.ml5 {
  margin-left: @spacer * 5 !important;
}
.ml6 {
  margin-left: @spacer * 6 !important;
}
