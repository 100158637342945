.order-segment {
  padding-top: 6px !important;
  padding-left: 8px !important;
}

.workorder-title__totals {
  padding-top: 0 !important;

  .segment {
    padding-top: 0.5rem !important;
    padding-bottom: 0.5rem !important;
  }
}

.order-search-parts {
  min-width: 350px;
}

@keyframes rotate1turn {
  from {
    transform: rotate(0turn);
  }

  to {
    transform: rotate(1turn);
  }
}

.order-rectify-card-animation {
  animation: rotate1turn 1s infinite linear;

  /* To make sure the spin is centered and smooth, make sure the height and width are equal */
  width: 20px !important;
  height: 20px !important;
}
