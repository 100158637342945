/*******************************
         Site Overrides
*******************************/

.ui.celled.fixed.striped.calendar-table {
  thead {
    tr:first-of-type {
      th {
        vertical-align: top;
        border-bottom: 3px solid @inputBorderColor;
        background: #fff;
        text-align: left;

        .header {
          padding: 4px;
          float: left;
        }
      }
    }
  }
  tbody {
    tr {
      height: 372px;
      td {
        padding: 0 0 0 0;
        background: linear-gradient(to bottom, #f3f3f3, #f3f3f3 50%, #ffffff 50%, #ffffff);

        background-size: 100% 93px;
      }
    }
  }
}
