// TODO: Refactor this

@tablePrefixCls: cbgms-grid;
@text-color: #666;
@font-size-base: 14px;
@line-height: 1.5;
@table-border-color: #e9e9e9;
@table-head-background-color: #f7f7f7;
@vertical-padding: 16px;
@horizontal-padding: 8px;

@grid-border-color: #e1e1e1;

@grid-background-color: #fff;
@grid-background-color-odd: #f3f3f3;

.@{tablePrefixCls} {
  font-size: @font-size-base;
  color: @text-color;
  transition: opacity 0.3s ease;
  position: relative;
  line-height: @line-height;
  overflow: hidden;

  &__resizer {
    cursor: col-resize;
    user-select: none;

    position: absolute;
    right: 0;
    top: 0;
    height: 100%;
    width: 5px;
  }

  &-filter {
    text-align: center;
    padding: 2px @horizontal-padding 0 @horizontal-padding;
    max-height: 40px;
    height: 40px;

    .ui.selection.dropdown {
      margin-top: 5px;
      padding: 8px;
      min-height: 32px;
    }

    .field {
      margin-top: 5px;
    }
  }

  &-headernav {
    border-bottom: 3px solid #727171; // main/assets/styles/ui.less @inputBorderColor
    display: flex;
    padding: 2px @horizontal-padding;
    width: 100%;
  }

  &-header-icons {
    display: flex;
    margin-left: auto;
  }

  .@{tablePrefixCls}-scroll {
    overflow: auto;

    table {
      width: auto;
      min-width: 100%;
    }
  }

  &-fixed {
    table-layout: fixed;
  }

  .@{tablePrefixCls}-header {
    overflow: visible;
    background: @table-head-background-color;

    .ui.fluid.input > input {
      height: 32px;
    }

    .button {
      padding: 0 8px !important;
    }

    tr {
      background-color: #fff !important;
    }
  }

  &-fixed-header &-body {
    background: #fff;
    position: relative;
  }

  &-fixed-header &-body-inner {
    height: 100%;
    overflow: scroll;
  }

  &-fixed-header &-scroll &-header {
    overflow-x: scroll;
    padding-bottom: 20px;
    margin-bottom: -20px;
    overflow-y: scroll;
    box-sizing: border-box;
  }

  // https://github.com/ant-design/ant-design/issues/10828
  &-fixed-columns-in-body {
    visibility: hidden;
    pointer-events: none;
  }

  .@{tablePrefixCls}-title {
    padding: @vertical-padding @horizontal-padding;
    border-top: 1px solid @table-border-color;
  }

  .@{tablePrefixCls}-content {
    position: relative;
  }

  .@{tablePrefixCls}-footer {
    padding: @vertical-padding @horizontal-padding;
    border-bottom: 1px solid @table-border-color;
  }

  .@{tablePrefixCls}-placeholder {
    padding: 16px 8px;
    background: #fff;
    border-bottom: 1px solid @table-border-color;
    text-align: center;
    position: relative;

    &-fixed-columns {
      position: absolute;
      bottom: 0;
      width: 100%;
      background: transparent;
      pointer-events: none;
    }
  }

  table {
    width: 100%;
    border-collapse: collapse;
    text-align: left;
  }

  th {
    background: @table-head-background-color;
    position: relative;
    font-weight: bold;
    transition: background 0.3s ease;
    padding: 0;

    text-align: left;

    // padding: @vertical-padding @horizontal-padding;
    // padding: 0px @horizontal-padding;
  }

  td,
  th {
    border-right: 1px solid @grid-border-color;
  }

  td {
    // border-bottom: 1px solid @table-border-color;
    // border-right: 1px solid @grid-border-color;
    // padding-left: 20px;

    &:empty:after {
      content: '.'; // empty cell placeholder
      visibility: hidden;
    }
  }

  tr:nth-child(odd) {
    background-color: @grid-background-color-odd;
  }

  tr {
    transition: all 0.3s ease;

    &:hover {
      background: #eaf8fe;
    }

    &.@{tablePrefixCls}-row-hover {
      background: #eaf8fe;
    }
  }

  &-name {
    display: inline-block;
    padding-left: 5px;
    padding-right: 5px;

    font-weight: 800;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }

  th,
  td {
    // padding: @vertical-padding @horizontal-padding;
    overflow: hidden;
  }

  td {
    white-space: nowrap;
    padding-left: 20px;
    padding-right: 20px;
  }
}

.@{tablePrefixCls} {
  &--image {
    max-height: 34px;
    padding-top: 10px;
    padding-bottom: 4px;
  }

  &-expand-icon-col {
    width: 34px;
  }

  &-row,
  &-expanded-row {
    &--hover {
      background-color: #eaf8fe !important; // Important because otherwise alternating row coloring takes presedence
    }

    &-expand-icon {
      cursor: pointer;
      display: inline-block;
      width: 16px;
      height: 16px;
      text-align: center;
      line-height: 16px;
      border: 1px solid @table-border-color;
      user-select: none;
      background: #fff;
    }

    &-spaced {
      visibility: hidden;
    }

    &-spaced:after {
      content: '.';
    }

    &-expanded:after {
      content: '-';
    }

    &-collapsed:after {
      content: '+';
    }
  }

  tr&-expanded-row {
    background: #f7f7f7;

    &:hover {
      background: #f7f7f7;
    }
  }

  &-column-hidden {
    display: none;
  }

  &-prev-columns-page,
  &-next-columns-page {
    cursor: pointer;
    color: #666;
    z-index: 1;

    &:hover {
      color: #2db7f5;
    }

    &-disabled {
      cursor: not-allowed;
      color: #999;

      &:hover {
        color: #999;
      }
    }
  }

  &-prev-columns-page {
    margin-right: 8px;

    &:before {
      content: '<';
    }
  }

  &-next-columns-page {
    float: right;

    &:before {
      content: '>';
    }
  }

  &-fixed-left,
  &-fixed-right {
    position: absolute;
    top: 0;
    overflow: hidden;

    table {
      width: auto;
      background: #fff;
    }
  }

  &-fixed-left {
    left: 0;
    box-shadow: 4px 0 4px rgba(100, 100, 100, 0.1);

    & .@{tablePrefixCls}-body-inner {
      margin-right: -20px;
      padding-right: 20px;
    }

    .@{tablePrefixCls}-fixed-header & .@{tablePrefixCls}-body-inner {
      padding-right: 0;
    }
  }

  .@{tablePrefixCls}-body-inner::-webkit-scrollbar {
    display:none;
  }

  .@{tablePrefixCls}-body-inner {
    scrollbar-width: none;
  }

  &-fixed-right {
    right: 0;
    box-shadow: -4px 0 4px rgba(100, 100, 100, 0.1);

    // hide expand row content in right fixed Table
    // https://github.com/ant-design/ant-design/issues/1898
    .@{tablePrefixCls}-expanded-row {
      color: transparent;
      pointer-events: none;
    }
  }

  &&-scroll-position-left &-fixed-left {
    box-shadow: none;
  }

  &&-scroll-position-right &-fixed-right {
    box-shadow: none;
  }

  &__raw-json-cell {
    background-color: darken(@grid-background-color, 8%);
    border-radius: inherit;
    margin: 0;
    padding: 1em;
  }

  &__lambda_error-cell {
    background-color: darken(@grid-background-color, 8%);
    border-radius: inherit;
    margin: 0;
    padding: 1em;
    width:600px;
    max-width: 600px;
    height:300px;
    max-height: 300px;
    overflow-y: auto;
  }

  &__lambda_error-stack {
    background-color: darken(@grid-background-color, 8%);
    border-radius: inherit;
    margin: 0;
    padding: 1em;
    width:600px;
    max-width: 600px;
  }

  &__lambda_error-cell-code {
    white-space: pre-wrap;
    overflow-wrap: break-word;
    overflow-x: scroll;
  }
}
