/*******************************
         Site Overrides
*******************************/

.ui.segment.summary{
  border-bottom: 5px solid var(--mainColour);
}

.ui.segment.main-color{
  border-top: 2px solid var(--mainColour);
}

.ui.horizontal.segments > .segment {
  flex: 1 1 0;
}
