// Indent child lines under their parent

.ui.table td.breaking {
  word-wrap: break-word;
}

.quotation-table {
  &.ui.celled.table tbody tr.child-line,
  &.ui.celled.table tbody tr.child-line:last-of-type {
    td {
      padding-top: 1px;
      border-top: 0;
    }

    td.product-code,
    td.product-description {
      padding-left: 20px;
      font-style: italic;
    }
  }

  &.ui.celled.table tbody tr {
    td {
      padding: 11px 11px 1px 11px;
    }
  }

  &.ui.celled.table tbody tr:last-of-type {
    td {
      padding: 11px;
    }
  }

  // Draw cell borders if no child lines are present
  &.ui.celled.table tbody {
    td {
      border-top: 1px solid rgba(34, 36, 38, 0.1)
    }
  }

  &.ui.celled.table tbody:first-of-type {
    td {
      border-top: none;
    }
  }
}

.repairTimes {
  column-count: 3;
  .onTablets({ column-count: 1; });
  .onSmallPc({ column-count: 2 });
}

