@image-border-color: #cccccc;
@image-selection-color: #2d5daa;

// delete modal can be opened in lightbox, so let's make sure the z-index is above the one in lightbox.
// see: packages/components/lightbox/Lightbox.tsx for reference z-index
.ui.modals.dimmer:has(.media-delete-confirm) {
  z-index: 1302;
}

.media-list {
  &__image {
    display: flex !important;
    align-items: center;
    height: 200px !important;
    max-height: 200px !important;
    border: 5px solid @image-border-color;
    overflow: hidden;
    margin: 0 !important;

    img {
      width: 100%; // IE11 fix
    }

    &--selected {
      border-color: @image-selection-color;
    }
  }

  &__image-check {
    position: absolute;
    top: -8px;
    right: -14px;
    z-index: 1;

    line-height: 1;
    width: 1.5em !important;
    height: 1.5em !important;
    padding: 0.25em 0em !important;
    border-radius: 5px;
    font-size: 1.5rem !important;
    border: 2px solid @image-border-color;
    background-color: white;
    color: white;

    &--selected {
      background-color: @image-selection-color;
      border-color: @image-selection-color;
    }
  }

  &__image-wrapper {
    display: inline-block;
    position: relative;
    margin-right: 8px;
    margin-bottom: 4px;
    width: 150px;
    vertical-align: middle;
    cursor: pointer;
  }

  &__filename {
    font-size: 10px;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
  }
}

.media-header {
  padding: 0.6em 1em;

  & > * {
    margin-top: 5px !important;
    margin-bottom: 5px !important;
  }
}
