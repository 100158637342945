
.popup-dropdown {
  margin-top: 0 !important;
  border-radius: 0 !important;
  padding: 0 !important;

  .menu-container {
    overflow: auto;
    max-height: 400px;
  }

  .menu,
  &__menu {
    margin: 5px;
  }

  .item,
  &__item {
    padding: 8px;

    &:hover {
      background-color: rgba(0, 0, 0, 0.05)
    }

    &.disabled {
      cursor: default;
      pointer-events: none;
      opacity: 0.45;
    }
  }
}
