@tiny: '../../img/vehiclebrandsprite12.png';
@normal: '../../img/vehiclebrandsprite25.png';
@large: '../../img/vehiclebrandsprite40.png';
@massive: '../../img/vehiclebrandsprite90.png';

.vehicle.brand {
  display: inline-block;
  width: 25px;
  height: 25px;
  background-image: url(@normal);
  background-repeat: no-repeat;
  background-position: -1px -1px;

  &.abarth {
    width: 25px;
    height: 25px;
    background-position: -28px -1px;
  }

  &.ac {
    width: 25px;
    height: 25px;
    background-position: -55px -1px;
  }

  &.acura {
    width: 25px;
    height: 25px;
    background-position: -82px -1px;
  }

  &.aeon {
    width: 25px;
    height: 25px;
    background-position: -109px -1px;
  }

  &.aixam {
    width: 25px;
    height: 25px;
    background-position: -136px -1px;
  }

  &.alfaromeo {
    width: 25px;
    height: 25px;
    background-position: -163px -1px;
  }

  &.alpina {
    width: 25px;
    height: 25px;
    background-position: -190px -1px;
  }

  &.alpine {
    width: 25px;
    height: 25px;
    background-position: -217px -1px;
  }

  &.amc {
    width: 25px;
    height: 25px;
    background-position: -244px -1px;
  }

  &.aprilia {
    width: 25px;
    height: 25px;
    background-position: -271px -1px;
  }

  &.aro {
    width: 25px;
    height: 25px;
    background-position: -298px -1px;
  }

  &.artega {
    width: 25px;
    height: 25px;
    background-position: -325px -1px;
  }

  &.asiamotors {
    width: 25px;
    height: 25px;
    background-position: -352px -1px;
  }

  &.astonmartin {
    width: 25px;
    height: 25px;
    background-position: -379px -1px;
  }

  &.audi {
    width: 25px;
    height: 25px;
    background-position: -1px -28px;
  }

  &.austin {
    width: 25px;
    height: 25px;
    background-position: -28px -28px;
  }

  &.austinhealey {
    width: 25px;
    height: 25px;
    background-position: -55px -28px;
  }

  &.autobianchi {
    width: 25px;
    height: 25px;
    background-position: -82px -28px;
  }

  &.autounion {
    width: 25px;
    height: 25px;
    background-position: -109px -28px;
  }

  &.baojun {
    width: 25px;
    height: 25px;
    background-position: -136px -28px;
  }

  &.barkas {
    width: 25px;
    height: 25px;
    background-position: -163px -28px;
  }

  &.bedford {
    width: 25px;
    height: 25px;
    background-position: -190px -28px;
  }

  &.beeline {
    width: 25px;
    height: 25px;
    background-position: -217px -28px;
  }

  &.benelli {
    width: 25px;
    height: 25px;
    background-position: -244px -28px;
  }

  &.bentley {
    width: 25px;
    height: 25px;
    background-position: -271px -28px;
  }

  &.bertone {
    width: 25px;
    height: 25px;
    background-position: -298px -28px;
  }

  &.besturn {
    width: 25px;
    height: 25px;
    background-position: -325px -28px;
  }

  &.beta {
    width: 25px;
    height: 25px;
    background-position: -352px -28px;
  }

  &.bimota {
    width: 25px;
    height: 25px;
    background-position: -379px -28px;
  }

  &.bitter {
    width: 25px;
    height: 25px;
    background-position: -1px -55px;
  }

  &.bmw {
    width: 25px;
    height: 25px;
    background-position: -28px -55px;
  }

  &.boatian {
    width: 25px;
    height: 25px;
    background-position: -55px -55px;
  }

  &.bond {
    width: 25px;
    height: 25px;
    background-position: -82px -55px;
  }

  &.borgward {
    width: 25px;
    height: 25px;
    background-position: -109px -55px;
  }

  &.bristol {
    width: 25px;
    height: 25px;
    background-position: -136px -55px;
  }

  &.buell {
    width: 25px;
    height: 25px;
    background-position: -163px -55px;
  }

  &.bugatti {
    width: 25px;
    height: 25px;
    background-position: -190px -55px;
  }

  &.buick {
    width: 25px;
    height: 25px;
    background-position: -217px -55px;
  }

  &.cadillac {
    width: 25px;
    height: 25px;
    background-position: -244px -55px;
  }

  &.cagiva {
    width: 25px;
    height: 25px;
    background-position: -271px -55px;
  }

  &.callaway {
    width: 25px;
    height: 25px;
    background-position: -298px -55px;
  }

  &.carbodies {
    width: 25px;
    height: 25px;
    background-position: -325px -55px;
  }

  &.caterham {
    width: 25px;
    height: 25px;
    background-position: -352px -55px;
  }

  &.cfmoto {
    width: 25px;
    height: 25px;
    background-position: -379px -55px;
  }

  &.checker {
    width: 25px;
    height: 25px;
    background-position: -1px -82px;
  }

  &.chevroletusa {
    width: 25px;
    height: 25px;
    background-position: -28px -82px;
  }

  &.chrysler {
    width: 25px;
    height: 25px;
    background-position: -55px -82px;
  }

  &.citroen {
    width: 25px;
    height: 25px;
    background-position: -82px -82px;
  }

  &.corvette {
    width: 25px;
    height: 25px;
    background-position: -109px -82px;
  }

  &.dacia {
    width: 25px;
    height: 25px;
    background-position: -136px -82px;
  }

  &.daelim {
    width: 25px;
    height: 25px;
    background-position: -163px -82px;
  }

  &.daewoochevrolet {
    width: 25px;
    height: 25px;
    background-position: -190px -82px;
  }

  &.daf {
    width: 25px;
    height: 25px;
    background-position: -217px -82px;
  }

  &.daihatsu {
    width: 25px;
    height: 25px;
    background-position: -244px -82px;
  }

  &.daimler {
    width: 25px;
    height: 25px;
    background-position: -271px -82px;
  }

  &.dallas {
    width: 25px;
    height: 25px;
    background-position: -298px -82px;
  }

  &.delorean {
    width: 25px;
    height: 25px;
    background-position: -325px -82px;
  }

  &.derbi {
    width: 25px;
    height: 25px;
    background-position: -352px -82px;
  }

  &.detomaso {
    width: 25px;
    height: 25px;
    background-position: -379px -82px;
  }

  &.diahatsu {
    width: 25px;
    height: 25px;
    background-position: -1px -109px;
  }

  &.dodge {
    width: 25px;
    height: 25px;
    background-position: -28px -109px;
  }

  &.dodge-ram {
    width: 25px;
    height: 25px;
    background-position: -55px -109px;
  }

  &.ds {
    width: 25px;
    height: 25px;
    background-position: -82px -109px;
  }

  &.ducati {
    width: 25px;
    height: 25px;
    background-position: -109px -109px;
  }

  &.eagle {
    width: 25px;
    height: 25px;
    background-position: -136px -109px;
  }

  &.ecm {
    width: 25px;
    height: 25px;
    background-position: -163px -109px;
  }

  &.emc {
    width: 25px;
    height: 25px;
    background-position: -190px -109px;
  }

  &.fantic {
    width: 25px;
    height: 25px;
    background-position: -217px -109px;
  }

  &.ferrari {
    width: 25px;
    height: 25px;
    background-position: -244px -109px;
  }

  &.fiat {
    width: 25px;
    height: 25px;
    background-position: -271px -109px;
  }

  &.fisker {
    width: 25px;
    height: 25px;
    background-position: -298px -109px;
  }

  &.ford {
    width: 25px;
    height: 25px;
    background-position: -325px -109px;
  }

  &.fordotosan {
    width: 25px;
    height: 25px;
    background-position: -352px -109px;
  }

  &.fordusa {
    width: 25px;
    height: 25px;
    background-position: -379px -109px;
  }

  &.fso {
    width: 25px;
    height: 25px;
    background-position: -1px -136px;
  }

  &.gasgas {
    width: 25px;
    height: 25px;
    background-position: -28px -136px;
  }

  &.gaz {
    width: 25px;
    height: 25px;
    background-position: -55px -136px;
  }

  &.geely {
    width: 25px;
    height: 25px;
    background-position: -82px -136px;
  }

  &.generic {
    width: 25px;
    height: 25px;
    background-position: -109px -136px;
  }

  &.geo {
    width: 25px;
    height: 25px;
    background-position: -136px -136px;
  }

  &.gilera {
    width: 25px;
    height: 25px;
    background-position: -163px -136px;
  }

  &.ginetta {
    width: 25px;
    height: 25px;
    background-position: -190px -136px;
  }

  &.glas {
    width: 25px;
    height: 25px;
    background-position: -217px -136px;
  }

  &.gmc {
    width: 25px;
    height: 25px;
    background-position: -244px -136px;
  }

  &.harleydavidsonmc {
    width: 25px;
    height: 25px;
    background-position: -271px -136px;
  }

  &.hercules {
    width: 25px;
    height: 25px;
    background-position: -298px -136px;
  }

  &.hindustan {
    width: 25px;
    height: 25px;
    background-position: -325px -136px;
  }

  &.hmracing {
    width: 25px;
    height: 25px;
    background-position: -352px -136px;
  }

  &.hobbycar {
    width: 25px;
    height: 25px;
    background-position: -379px -136px;
  }

  &.holden {
    width: 25px;
    height: 25px;
    background-position: -1px -163px;
  }

  &.honda {
    width: 25px;
    height: 25px;
    background-position: -28px -163px;
  }

  &.hummer {
    width: 25px;
    height: 25px;
    background-position: -55px -163px;
  }

  &.husaberg {
    width: 25px;
    height: 25px;
    background-position: -82px -163px;
  }

  &.husqvarna {
    width: 25px;
    height: 25px;
    background-position: -109px -163px;
  }

  &.hyosung {
    width: 25px;
    height: 25px;
    background-position: -136px -163px;
  }

  &.hyundai {
    width: 25px;
    height: 25px;
    background-position: -163px -163px;
  }

  &.indian {
    width: 25px;
    height: 25px;
    background-position: -190px -163px;
  }

  &.indigo {
    width: 25px;
    height: 25px;
    background-position: -217px -163px;
  }

  &.infiniti {
    width: 25px;
    height: 25px;
    background-position: -244px -163px;
  }

  &.innocenti {
    width: 25px;
    height: 25px;
    background-position: -271px -163px;
  }

  &.irmscher {
    width: 25px;
    height: 25px;
    background-position: -298px -163px;
  }

  &.isdera {
    width: 25px;
    height: 25px;
    background-position: -325px -163px;
  }

  &.ish {
    width: 25px;
    height: 25px;
    background-position: -352px -163px;
  }

  &.isuzu {
    width: 25px;
    height: 25px;
    background-position: -379px -163px;
  }

  &.italjet {
    width: 25px;
    height: 25px;
    background-position: -1px -190px;
  }

  &.iveco {
    width: 25px;
    height: 25px;
    background-position: -28px -190px;
  }

  &.jaguar {
    width: 25px;
    height: 25px;
    background-position: -55px -190px;
  }

  &.jawa {
    width: 25px;
    height: 25px;
    background-position: -82px -190px;
  }

  &.jeep {
    width: 25px;
    height: 25px;
    background-position: -109px -190px;
  }

  &.jensen {
    width: 25px;
    height: 25px;
    background-position: -136px -190px;
  }

  &.kawasaki {
    width: 25px;
    height: 25px;
    background-position: -163px -190px;
  }

  &.kia {
    width: 25px;
    height: 25px;
    background-position: -190px -190px;
  }

  &.kreidler {
    width: 25px;
    height: 25px;
    background-position: -217px -190px;
  }

  &.ksr {
    width: 25px;
    height: 25px;
    background-position: -244px -190px;
  }

  &.ktm {
    width: 25px;
    height: 25px;
    background-position: -271px -190px;
  }

  &.kymco {
    width: 25px;
    height: 25px;
    background-position: -298px -190px;
  }

  &.lada {
    width: 25px;
    height: 25px;
    background-position: -325px -190px;
  }

  &.lamborghini {
    width: 25px;
    height: 25px;
    background-position: -352px -190px;
  }

  &.lancia {
    width: 25px;
    height: 25px;
    background-position: -379px -190px;
  }

  &.landrover {
    width: 25px;
    height: 25px;
    background-position: -1px -217px;
  }

  &.landwind-jmc {
    width: 25px;
    height: 25px;
    background-position: -28px -217px;
  }

  &.laverda {
    width: 25px;
    height: 25px;
    background-position: -55px -217px;
  }

  &.leylanddafldv {
    width: 25px;
    height: 25px;
    background-position: -82px -217px;
  }

  &.lexus {
    width: 25px;
    height: 25px;
    background-position: -109px -217px;
  }

  &.ligier {
    width: 25px;
    height: 25px;
    background-position: -136px -217px;
  }

  &.lincoln {
    width: 25px;
    height: 25px;
    background-position: -163px -217px;
  }

  &.lml {
    width: 25px;
    height: 25px;
    background-position: -190px -217px;
  }

  &.lotus {
    width: 25px;
    height: 25px;
    background-position: -217px -217px;
  }

  &.lti {
    width: 25px;
    height: 25px;
    background-position: -244px -217px;
  }

  &.mahindra {
    width: 25px;
    height: 25px;
    background-position: -271px -217px;
  }

  &.maico {
    width: 25px;
    height: 25px;
    background-position: -298px -217px;
  }

  &.malaguti {
    width: 25px;
    height: 25px;
    background-position: -325px -217px;
  }

  &.marcos {
    width: 25px;
    height: 25px;
    background-position: -352px -217px;
  }

  &.maserati {
    width: 25px;
    height: 25px;
    background-position: -379px -217px;
  }

  &.maybach {
    width: 25px;
    height: 25px;
    background-position: -1px -244px;
  }

  &.mazda {
    width: 25px;
    height: 25px;
    background-position: -28px -244px;
  }

  &.mbk {
    width: 25px;
    height: 25px;
    background-position: -55px -244px;
  }

  &.mclaren {
    width: 25px;
    height: 25px;
    background-position: -82px -244px;
  }

  &.mega {
    width: 25px;
    height: 25px;
    background-position: -109px -244px;
  }

  &.mercedes,
  &.mercedesbenz,
  &.mercedes-benz {
    width: 25px;
    height: 25px;
    background-position: -136px -244px;
  }

  &.mercury {
    width: 25px;
    height: 25px;
    background-position: -163px -244px;
  }

  &.metrocab {
    width: 25px;
    height: 25px;
    background-position: -190px -244px;
  }

  &.mg {
    width: 25px;
    height: 25px;
    background-position: -217px -244px;
  }

  &.middlebridge {
    width: 25px;
    height: 25px;
    background-position: -244px -244px;
  }

  &.minelli {
    width: 25px;
    height: 25px;
    background-position: -271px -244px;
  }

  &.mini {
    width: 25px;
    height: 25px;
    background-position: -298px -244px;
  }

  &.mitsubishi {
    width: 25px;
    height: 25px;
    background-position: -325px -244px;
  }

  &.mitsuoka {
    width: 25px;
    height: 25px;
    background-position: -352px -244px;
  }

  &.morgan {
    width: 25px;
    height: 25px;
    background-position: -379px -244px;
  }

  &.morris {
    width: 25px;
    height: 25px;
    background-position: -1px -271px;
  }

  &.moskvich {
    width: 25px;
    height: 25px;
    background-position: -28px -271px;
  }

  &.motobi {
    width: 25px;
    height: 25px;
    background-position: -55px -271px;
  }

  &.motoguzzimc {
    width: 25px;
    height: 25px;
    background-position: -82px -271px;
  }

  &.motomorinimc {
    width: 25px;
    height: 25px;
    background-position: -109px -271px;
  }

  &.mvagusta {
    width: 25px;
    height: 25px;
    background-position: -136px -271px;
  }

  &.mz {
    width: 25px;
    height: 25px;
    background-position: -163px -271px;
  }

  &.nissan {
    width: 25px;
    height: 25px;
    background-position: -190px -271px;
  }

  &.nsu {
    width: 25px;
    height: 25px;
    background-position: -217px -271px;
  }

  &.oldsmobile {
    width: 25px;
    height: 25px;
    background-position: -244px -271px;
  }

  &.oltcit {
    width: 25px;
    height: 25px;
    background-position: -271px -271px;
  }

  &.opel {
    width: 25px;
    height: 25px;
    background-position: -298px -271px;
  }

  &.osca {
    width: 25px;
    height: 25px;
    background-position: -325px -271px;
  }

  &.panoz {
    width: 25px;
    height: 25px;
    background-position: -352px -271px;
  }

  &.panther {
    width: 25px;
    height: 25px;
    background-position: -379px -271px;
  }

  &.paykan {
    width: 25px;
    height: 25px;
    background-position: -1px -298px;
  }

  &.peugeot {
    width: 25px;
    height: 25px;
    background-position: -28px -298px;
  }

  &.pgo {
    width: 25px;
    height: 25px;
    background-position: -55px -298px;
  }

  &.piaggio {
    width: 25px;
    height: 25px;
    background-position: -82px -298px;
  }

  &.pininfarina {
    width: 25px;
    height: 25px;
    background-position: -109px -298px;
  }

  &.plymouth {
    width: 25px;
    height: 25px;
    background-position: -136px -298px;
  }

  &.pontiac {
    width: 25px;
    height: 25px;
    background-position: -163px -298px;
  }

  &.porsche {
    width: 25px;
    height: 25px;
    background-position: -190px -298px;
  }

  &.premier {
    width: 25px;
    height: 25px;
    background-position: -217px -298px;
  }

  &.proton {
    width: 25px;
    height: 25px;
    background-position: -244px -298px;
  }

  &.puch {
    width: 25px;
    height: 25px;
    background-position: -271px -298px;
  }

  &.quadro {
    width: 25px;
    height: 25px;
    background-position: -298px -298px;
  }

  &.ranger {
    width: 25px;
    height: 25px;
    background-position: -325px -298px;
  }

  &.raytonfissore {
    width: 25px;
    height: 25px;
    background-position: -352px -298px;
  }

  &.reliant {
    width: 25px;
    height: 25px;
    background-position: -379px -298px;
  }

  &.renault {
    width: 25px;
    height: 25px;
    background-position: -1px -325px;
  }

  &.renaulttrucks {
    width: 25px;
    height: 25px;
    background-position: -28px -325px;
  }

  &.rex {
    width: 25px;
    height: 25px;
    background-position: -55px -325px;
  }

  &.riley {
    width: 25px;
    height: 25px;
    background-position: -82px -325px;
  }

  &.rivero {
    width: 25px;
    height: 25px;
    background-position: -109px -325px;
  }

  &.rollsroyce {
    width: 25px;
    height: 25px;
    background-position: -136px -325px;
  }

  &.rover {
    width: 25px;
    height: 25px;
    background-position: -163px -325px;
  }

  &.ruf {
    width: 25px;
    height: 25px;
    background-position: -190px -325px;
  }

  &.saab {
    width: 25px;
    height: 25px;
    background-position: -217px -325px;
  }

  &.sachs {
    width: 25px;
    height: 25px;
    background-position: -244px -325px;
  }

  &.sanglas {
    width: 25px;
    height: 25px;
    background-position: -271px -325px;
  }

  &.santana {
    width: 25px;
    height: 25px;
    background-position: -298px -325px;
  }

  &.seat {
    width: 25px;
    height: 25px;
    background-position: -325px -325px;
  }

  &.shelby {
    width: 25px;
    height: 25px;
    background-position: -352px -325px;
  }

  &.simson {
    width: 25px;
    height: 25px;
    background-position: -379px -325px;
  }

  &.sipani {
    width: 25px;
    height: 25px;
    background-position: -1px -352px;
  }

  &.skoda {
    width: 25px;
    height: 25px;
    background-position: -28px -352px;
  }

  &.smart {
    width: 25px;
    height: 25px;
    background-position: -55px -352px;
  }

  &.solo {
    width: 25px;
    height: 25px;
    background-position: -82px -352px;
  }

  &.sommer {
    width: 25px;
    height: 25px;
    background-position: -109px -352px;
  }

  &.spectre {
    width: 25px;
    height: 25px;
    background-position: -136px -352px;
  }

  &.spyker {
    width: 25px;
    height: 25px;
    background-position: -163px -352px;
  }

  &.ssangyong {
    width: 25px;
    height: 25px;
    background-position: -190px -352px;
  }

  &.standard {
    width: 25px;
    height: 25px;
    background-position: -217px -352px;
  }

  &.steyr {
    width: 25px;
    height: 25px;
    background-position: -244px -352px;
  }

  &.streetscooter {
    width: 25px;
    height: 25px;
    background-position: -271px -352px;
  }

  &.subaru {
    width: 25px;
    height: 25px;
    background-position: -298px -352px;
  }

  &.suzuki {
    width: 25px;
    height: 25px;
    background-position: -325px -352px;
  }

  &.swm {
    width: 25px;
    height: 25px;
    background-position: -352px -352px;
  }

  &.sym {
    width: 25px;
    height: 25px;
    background-position: -379px -352px;
  }

  &.talbot {
    width: 25px;
    height: 25px;
    background-position: -1px -379px;
  }

  &.tata {
    width: 25px;
    height: 25px;
    background-position: -28px -379px;
  }

  &.tazzari {
    width: 25px;
    height: 25px;
    background-position: -55px -379px;
  }

  &.tesla {
    width: 25px;
    height: 25px;
    background-position: -82px -379px;
  }

  &.tgb {
    width: 25px;
    height: 25px;
    background-position: -109px -379px;
  }

  &.thnk {
    width: 25px;
    height: 25px;
    background-position: -136px -379px;
  }

  &.tomos {
    width: 25px;
    height: 25px;
    background-position: -163px -379px;
  }

  &.tornax {
    width: 25px;
    height: 25px;
    background-position: -190px -379px;
  }

  &.toyota {
    width: 25px;
    height: 25px;
    background-position: -217px -379px;
  }

  &.trabant {
    width: 25px;
    height: 25px;
    background-position: -244px -379px;
  }

  &.triumph {
    width: 25px;
    height: 25px;
    background-position: -271px -379px;
  }

  &.tvr {
    width: 25px;
    height: 25px;
    background-position: -298px -379px;
  }

  &.uaz {
    width: 25px;
    height: 25px;
    background-position: -325px -379px;
  }

  &.umm {
    width: 25px;
    height: 25px;
    background-position: -352px -379px;
  }

  &.vauxhall {
    width: 25px;
    height: 25px;
    background-position: -379px -379px;
  }

  &.vector {
    width: 25px;
    height: 25px;
    background-position: -406px -1px;
  }

  &.vespa {
    width: 25px;
    height: 25px;
    background-position: -406px -28px;
  }

  &.victory {
    width: 25px;
    height: 25px;
    background-position: -406px -55px;
  }

  &.volkswagen {
    width: 25px;
    height: 25px;
    background-position: -406px -82px;
  }

  &.volvo {
    width: 25px;
    height: 25px;
    background-position: -406px -109px;
  }

  &.vw {
    width: 25px;
    height: 25px;
    background-position: -406px -136px;
  }

  &.vw-svw {
    width: 25px;
    height: 25px;
    background-position: -406px -163px;
  }

  &.wartburg {
    width: 25px;
    height: 25px;
    background-position: -406px -190px;
  }

  &.westfield {
    width: 25px;
    height: 25px;
    background-position: -406px -217px;
  }

  &.wiesmann {
    width: 25px;
    height: 25px;
    background-position: -406px -244px;
  }

  &.wolseley {
    width: 25px;
    height: 25px;
    background-position: -406px -271px;
  }

  &.yamaha {
    width: 25px;
    height: 25px;
    background-position: -406px -298px;
  }

  &.yulon {
    width: 25px;
    height: 25px;
    background-position: -406px -325px;
  }

  &.yugozastava {
    width: 25px;
    height: 25px;
    background-position: -406px -352px;
  }

  &.zaz {
    width: 25px;
    height: 25px;
    background-position: -406px -379px;
  }

  &.zero {
    width: 25px;
    height: 25px;
    background-position: -1px -406px;
  }

  &.zuendapp {
    width: 25px;
    height: 25px;
    background-position: -28px -406px;
  }

  &.tiny {
    width: 12px;
    height: 12px;
    background-image: url(@tiny);
    background-position: -1px -1px;

    &.abarth {
      width: 12px;
      height: 12px;
      background-position: -15px -1px;
    }

    &.ac {
      width: 12px;
      height: 12px;
      background-position: -29px -1px;
    }

    &.acura {
      width: 12px;
      height: 12px;
      background-position: -43px -1px;
    }

    &.aeon {
      width: 12px;
      height: 12px;
      background-position: -57px -1px;
    }

    &.aixam {
      width: 12px;
      height: 12px;
      background-position: -71px -1px;
    }

    &.alfaromeo {
      width: 12px;
      height: 12px;
      background-position: -85px -1px;
    }

    &.alpina {
      width: 12px;
      height: 12px;
      background-position: -99px -1px;
    }

    &.alpine {
      width: 12px;
      height: 12px;
      background-position: -113px -1px;
    }

    &.amc {
      width: 12px;
      height: 12px;
      background-position: -127px -1px;
    }

    &.aprilia {
      width: 12px;
      height: 12px;
      background-position: -141px -1px;
    }

    &.aro {
      width: 12px;
      height: 12px;
      background-position: -155px -1px;
    }

    &.artega {
      width: 12px;
      height: 12px;
      background-position: -169px -1px;
    }

    &.asiamotors {
      width: 12px;
      height: 12px;
      background-position: -183px -1px;
    }

    &.astonmartin {
      width: 12px;
      height: 12px;
      background-position: -197px -1px;
    }

    &.audi {
      width: 12px;
      height: 12px;
      background-position: -1px -15px;
    }

    &.austin {
      width: 12px;
      height: 12px;
      background-position: -15px -15px;
    }

    &.austinhealey {
      width: 12px;
      height: 12px;
      background-position: -29px -15px;
    }

    &.autobianchi {
      width: 12px;
      height: 12px;
      background-position: -43px -15px;
    }

    &.autounion {
      width: 12px;
      height: 12px;
      background-position: -57px -15px;
    }

    &.baojun {
      width: 12px;
      height: 12px;
      background-position: -71px -15px;
    }

    &.barkas {
      width: 12px;
      height: 12px;
      background-position: -85px -15px;
    }

    &.bedford {
      width: 12px;
      height: 12px;
      background-position: -99px -15px;
    }

    &.beeline {
      width: 12px;
      height: 12px;
      background-position: -113px -15px;
    }

    &.benelli {
      width: 12px;
      height: 12px;
      background-position: -127px -15px;
    }

    &.bentley {
      width: 12px;
      height: 12px;
      background-position: -141px -15px;
    }

    &.bertone {
      width: 12px;
      height: 12px;
      background-position: -155px -15px;
    }

    &.besturn {
      width: 12px;
      height: 12px;
      background-position: -169px -15px;
    }

    &.beta {
      width: 12px;
      height: 12px;
      background-position: -183px -15px;
    }

    &.bimota {
      width: 12px;
      height: 12px;
      background-position: -197px -15px;
    }

    &.bitter {
      width: 12px;
      height: 12px;
      background-position: -1px -29px;
    }

    &.bmw {
      width: 12px;
      height: 12px;
      background-position: -15px -29px;
    }

    &.boatian {
      width: 12px;
      height: 12px;
      background-position: -29px -29px;
    }

    &.bond {
      width: 12px;
      height: 12px;
      background-position: -43px -29px;
    }

    &.borgward {
      width: 12px;
      height: 12px;
      background-position: -57px -29px;
    }

    &.bristol {
      width: 12px;
      height: 12px;
      background-position: -71px -29px;
    }

    &.buell {
      width: 12px;
      height: 12px;
      background-position: -85px -29px;
    }

    &.bugatti {
      width: 12px;
      height: 12px;
      background-position: -99px -29px;
    }

    &.buick {
      width: 12px;
      height: 12px;
      background-position: -113px -29px;
    }

    &.cadillac {
      width: 12px;
      height: 12px;
      background-position: -127px -29px;
    }

    &.cagiva {
      width: 12px;
      height: 12px;
      background-position: -141px -29px;
    }

    &.callaway {
      width: 12px;
      height: 12px;
      background-position: -155px -29px;
    }

    &.carbodies {
      width: 12px;
      height: 12px;
      background-position: -169px -29px;
    }

    &.caterham {
      width: 12px;
      height: 12px;
      background-position: -183px -29px;
    }

    &.cfmoto {
      width: 12px;
      height: 12px;
      background-position: -197px -29px;
    }

    &.checker {
      width: 12px;
      height: 12px;
      background-position: -1px -43px;
    }

    &.chevroletusa {
      width: 12px;
      height: 12px;
      background-position: -15px -43px;
    }

    &.chrysler {
      width: 12px;
      height: 12px;
      background-position: -29px -43px;
    }

    &.citroen {
      width: 12px;
      height: 12px;
      background-position: -43px -43px;
    }

    &.corvette {
      width: 12px;
      height: 12px;
      background-position: -57px -43px;
    }

    &.dacia {
      width: 12px;
      height: 12px;
      background-position: -71px -43px;
    }

    &.daelim {
      width: 12px;
      height: 12px;
      background-position: -85px -43px;
    }

    &.daewoochevrolet {
      width: 12px;
      height: 12px;
      background-position: -99px -43px;
    }

    &.daf {
      width: 12px;
      height: 12px;
      background-position: -113px -43px;
    }

    &.daihatsu {
      width: 12px;
      height: 12px;
      background-position: -127px -43px;
    }

    &.daimler {
      width: 12px;
      height: 12px;
      background-position: -141px -43px;
    }

    &.dallas {
      width: 12px;
      height: 12px;
      background-position: -155px -43px;
    }

    &.delorean {
      width: 12px;
      height: 12px;
      background-position: -169px -43px;
    }

    &.derbi {
      width: 12px;
      height: 12px;
      background-position: -183px -43px;
    }

    &.detomaso {
      width: 12px;
      height: 12px;
      background-position: -197px -43px;
    }

    &.diahatsu {
      width: 12px;
      height: 12px;
      background-position: -1px -57px;
    }

    &.dodge {
      width: 12px;
      height: 12px;
      background-position: -15px -57px;
    }

    &.dodge-ram {
      width: 12px;
      height: 12px;
      background-position: -29px -57px;
    }

    &.ds {
      width: 12px;
      height: 12px;
      background-position: -43px -57px;
    }

    &.ducati {
      width: 12px;
      height: 12px;
      background-position: -57px -57px;
    }

    &.eagle {
      width: 12px;
      height: 12px;
      background-position: -71px -57px;
    }

    &.ecm {
      width: 12px;
      height: 12px;
      background-position: -85px -57px;
    }

    &.emc {
      width: 12px;
      height: 12px;
      background-position: -99px -57px;
    }

    &.fantic {
      width: 12px;
      height: 12px;
      background-position: -113px -57px;
    }

    &.ferrari {
      width: 12px;
      height: 12px;
      background-position: -127px -57px;
    }

    &.fiat {
      width: 12px;
      height: 12px;
      background-position: -141px -57px;
    }

    &.fisker {
      width: 12px;
      height: 12px;
      background-position: -155px -57px;
    }

    &.ford {
      width: 12px;
      height: 12px;
      background-position: -169px -57px;
    }

    &.fordotosan {
      width: 12px;
      height: 12px;
      background-position: -183px -57px;
    }

    &.fordusa {
      width: 12px;
      height: 12px;
      background-position: -197px -57px;
    }

    &.fso {
      width: 12px;
      height: 12px;
      background-position: -1px -71px;
    }

    &.gasgas {
      width: 12px;
      height: 12px;
      background-position: -15px -71px;
    }

    &.gaz {
      width: 12px;
      height: 12px;
      background-position: -29px -71px;
    }

    &.geely {
      width: 12px;
      height: 12px;
      background-position: -43px -71px;
    }

    &.generic {
      width: 12px;
      height: 12px;
      background-position: -57px -71px;
    }

    &.geo {
      width: 12px;
      height: 12px;
      background-position: -71px -71px;
    }

    &.gilera {
      width: 12px;
      height: 12px;
      background-position: -85px -71px;
    }

    &.ginetta {
      width: 12px;
      height: 12px;
      background-position: -99px -71px;
    }

    &.glas {
      width: 12px;
      height: 12px;
      background-position: -113px -71px;
    }

    &.gmc {
      width: 12px;
      height: 12px;
      background-position: -127px -71px;
    }

    &.harleydavidsonmc {
      width: 12px;
      height: 12px;
      background-position: -141px -71px;
    }

    &.hercules {
      width: 12px;
      height: 12px;
      background-position: -155px -71px;
    }

    &.hindustan {
      width: 12px;
      height: 12px;
      background-position: -169px -71px;
    }

    &.hmracing {
      width: 12px;
      height: 12px;
      background-position: -183px -71px;
    }

    &.hobbycar {
      width: 12px;
      height: 12px;
      background-position: -197px -71px;
    }

    &.holden {
      width: 12px;
      height: 12px;
      background-position: -1px -85px;
    }

    &.honda {
      width: 12px;
      height: 12px;
      background-position: -15px -85px;
    }

    &.hummer {
      width: 12px;
      height: 12px;
      background-position: -29px -85px;
    }

    &.husaberg {
      width: 12px;
      height: 12px;
      background-position: -43px -85px;
    }

    &.husqvarna {
      width: 12px;
      height: 12px;
      background-position: -57px -85px;
    }

    &.hyosung {
      width: 12px;
      height: 12px;
      background-position: -71px -85px;
    }

    &.hyundai {
      width: 12px;
      height: 12px;
      background-position: -85px -85px;
    }

    &.indian {
      width: 12px;
      height: 12px;
      background-position: -99px -85px;
    }

    &.indigo {
      width: 12px;
      height: 12px;
      background-position: -113px -85px;
    }

    &.infiniti {
      width: 12px;
      height: 12px;
      background-position: -127px -85px;
    }

    &.innocenti {
      width: 12px;
      height: 12px;
      background-position: -141px -85px;
    }

    &.irmscher {
      width: 12px;
      height: 12px;
      background-position: -155px -85px;
    }

    &.isdera {
      width: 12px;
      height: 12px;
      background-position: -169px -85px;
    }

    &.ish {
      width: 12px;
      height: 12px;
      background-position: -183px -85px;
    }

    &.isuzu {
      width: 12px;
      height: 12px;
      background-position: -197px -85px;
    }

    &.italjet {
      width: 12px;
      height: 12px;
      background-position: -1px -99px;
    }

    &.iveco {
      width: 12px;
      height: 12px;
      background-position: -15px -99px;
    }

    &.jaguar {
      width: 12px;
      height: 12px;
      background-position: -29px -99px;
    }

    &.jawa {
      width: 12px;
      height: 12px;
      background-position: -43px -99px;
    }

    &.jeep {
      width: 12px;
      height: 12px;
      background-position: -57px -99px;
    }

    &.jensen {
      width: 12px;
      height: 12px;
      background-position: -71px -99px;
    }

    &.kawasaki {
      width: 12px;
      height: 12px;
      background-position: -85px -99px;
    }

    &.kia {
      width: 12px;
      height: 12px;
      background-position: -99px -99px;
    }

    &.kreidler {
      width: 12px;
      height: 12px;
      background-position: -113px -99px;
    }

    &.ksr {
      width: 12px;
      height: 12px;
      background-position: -127px -99px;
    }

    &.ktm {
      width: 12px;
      height: 12px;
      background-position: -141px -99px;
    }

    &.kymco {
      width: 12px;
      height: 12px;
      background-position: -155px -99px;
    }

    &.lada {
      width: 12px;
      height: 12px;
      background-position: -169px -99px;
    }

    &.lamborghini {
      width: 12px;
      height: 12px;
      background-position: -183px -99px;
    }

    &.lancia {
      width: 12px;
      height: 12px;
      background-position: -197px -99px;
    }

    &.landrover {
      width: 12px;
      height: 12px;
      background-position: -1px -113px;
    }

    &.landwind-jmc {
      width: 12px;
      height: 12px;
      background-position: -15px -113px;
    }

    &.laverda {
      width: 12px;
      height: 12px;
      background-position: -29px -113px;
    }

    &.leylanddafldv {
      width: 12px;
      height: 12px;
      background-position: -43px -113px;
    }

    &.lexus {
      width: 12px;
      height: 12px;
      background-position: -57px -113px;
    }

    &.ligier {
      width: 12px;
      height: 12px;
      background-position: -71px -113px;
    }

    &.lincoln {
      width: 12px;
      height: 12px;
      background-position: -85px -113px;
    }

    &.lml {
      width: 12px;
      height: 12px;
      background-position: -99px -113px;
    }

    &.lotus {
      width: 12px;
      height: 12px;
      background-position: -113px -113px;
    }

    &.lti {
      width: 12px;
      height: 12px;
      background-position: -127px -113px;
    }

    &.mahindra {
      width: 12px;
      height: 12px;
      background-position: -141px -113px;
    }

    &.maico {
      width: 12px;
      height: 12px;
      background-position: -155px -113px;
    }

    &.malaguti {
      width: 12px;
      height: 12px;
      background-position: -169px -113px;
    }

    &.marcos {
      width: 12px;
      height: 12px;
      background-position: -183px -113px;
    }

    &.maserati {
      width: 12px;
      height: 12px;
      background-position: -197px -113px;
    }

    &.maybach {
      width: 12px;
      height: 12px;
      background-position: -1px -127px;
    }

    &.mazda {
      width: 12px;
      height: 12px;
      background-position: -15px -127px;
    }

    &.mbk {
      width: 12px;
      height: 12px;
      background-position: -29px -127px;
    }

    &.mclaren {
      width: 12px;
      height: 12px;
      background-position: -43px -127px;
    }

    &.mega {
      width: 12px;
      height: 12px;
      background-position: -57px -127px;
    }

    &.mercedes,
    &.mercedesbenz,
    &.mercedes-benz  {
      width: 12px;
      height: 12px;
      background-position: -71px -127px;
    }

    &.mercury {
      width: 12px;
      height: 12px;
      background-position: -85px -127px;
    }

    &.metrocab {
      width: 12px;
      height: 12px;
      background-position: -99px -127px;
    }

    &.mg {
      width: 12px;
      height: 12px;
      background-position: -113px -127px;
    }

    &.middlebridge {
      width: 12px;
      height: 12px;
      background-position: -127px -127px;
    }

    &.minelli {
      width: 12px;
      height: 12px;
      background-position: -141px -127px;
    }

    &.mini {
      width: 12px;
      height: 12px;
      background-position: -155px -127px;
    }

    &.mitsubishi {
      width: 12px;
      height: 12px;
      background-position: -169px -127px;
    }

    &.mitsuoka {
      width: 12px;
      height: 12px;
      background-position: -183px -127px;
    }

    &.morgan {
      width: 12px;
      height: 12px;
      background-position: -197px -127px;
    }

    &.morris {
      width: 12px;
      height: 12px;
      background-position: -1px -141px;
    }

    &.moskvich {
      width: 12px;
      height: 12px;
      background-position: -15px -141px;
    }

    &.motobi {
      width: 12px;
      height: 12px;
      background-position: -29px -141px;
    }

    &.motoguzzimc {
      width: 12px;
      height: 12px;
      background-position: -43px -141px;
    }

    &.motomorinimc {
      width: 12px;
      height: 12px;
      background-position: -57px -141px;
    }

    &.mvagusta {
      width: 12px;
      height: 12px;
      background-position: -71px -141px;
    }

    &.mz {
      width: 12px;
      height: 12px;
      background-position: -85px -141px;
    }

    &.nissan {
      width: 12px;
      height: 12px;
      background-position: -99px -141px;
    }

    &.nsu {
      width: 12px;
      height: 12px;
      background-position: -113px -141px;
    }

    &.oldsmobile {
      width: 12px;
      height: 12px;
      background-position: -127px -141px;
    }

    &.oltcit {
      width: 12px;
      height: 12px;
      background-position: -141px -141px;
    }

    &.opel {
      width: 12px;
      height: 12px;
      background-position: -155px -141px;
    }

    &.osca {
      width: 12px;
      height: 12px;
      background-position: -169px -141px;
    }

    &.panoz {
      width: 12px;
      height: 12px;
      background-position: -183px -141px;
    }

    &.panther {
      width: 12px;
      height: 12px;
      background-position: -197px -141px;
    }

    &.paykan {
      width: 12px;
      height: 12px;
      background-position: -1px -155px;
    }

    &.peugeot {
      width: 12px;
      height: 12px;
      background-position: -15px -155px;
    }

    &.pgo {
      width: 12px;
      height: 12px;
      background-position: -29px -155px;
    }

    &.piaggio {
      width: 12px;
      height: 12px;
      background-position: -43px -155px;
    }

    &.pininfarina {
      width: 12px;
      height: 12px;
      background-position: -57px -155px;
    }

    &.plymouth {
      width: 12px;
      height: 12px;
      background-position: -71px -155px;
    }

    &.pontiac {
      width: 12px;
      height: 12px;
      background-position: -85px -155px;
    }

    &.porsche {
      width: 12px;
      height: 12px;
      background-position: -99px -155px;
    }

    &.premier {
      width: 12px;
      height: 12px;
      background-position: -113px -155px;
    }

    &.proton {
      width: 12px;
      height: 12px;
      background-position: -127px -155px;
    }

    &.puch {
      width: 12px;
      height: 12px;
      background-position: -141px -155px;
    }

    &.quadro {
      width: 12px;
      height: 12px;
      background-position: -155px -155px;
    }

    &.ranger {
      width: 12px;
      height: 12px;
      background-position: -169px -155px;
    }

    &.raytonfissore {
      width: 12px;
      height: 12px;
      background-position: -183px -155px;
    }

    &.reliant {
      width: 12px;
      height: 12px;
      background-position: -197px -155px;
    }

    &.renault {
      width: 12px;
      height: 12px;
      background-position: -1px -169px;
    }

    &.renaulttrucks {
      width: 12px;
      height: 12px;
      background-position: -15px -169px;
    }

    &.rex {
      width: 12px;
      height: 12px;
      background-position: -29px -169px;
    }

    &.riley {
      width: 12px;
      height: 12px;
      background-position: -43px -169px;
    }

    &.rivero {
      width: 12px;
      height: 12px;
      background-position: -57px -169px;
    }

    &.rollsroyce {
      width: 12px;
      height: 12px;
      background-position: -71px -169px;
    }

    &.rover {
      width: 12px;
      height: 12px;
      background-position: -85px -169px;
    }

    &.ruf {
      width: 12px;
      height: 12px;
      background-position: -99px -169px;
    }

    &.saab {
      width: 12px;
      height: 12px;
      background-position: -113px -169px;
    }

    &.sachs {
      width: 12px;
      height: 12px;
      background-position: -127px -169px;
    }

    &.sanglas {
      width: 12px;
      height: 12px;
      background-position: -141px -169px;
    }

    &.santana {
      width: 12px;
      height: 12px;
      background-position: -155px -169px;
    }

    &.seat {
      width: 12px;
      height: 12px;
      background-position: -169px -169px;
    }

    &.shelby {
      width: 12px;
      height: 12px;
      background-position: -183px -169px;
    }

    &.simson {
      width: 12px;
      height: 12px;
      background-position: -197px -169px;
    }

    &.sipani {
      width: 12px;
      height: 12px;
      background-position: -1px -183px;
    }

    &.skoda {
      width: 12px;
      height: 12px;
      background-position: -15px -183px;
    }

    &.smart {
      width: 12px;
      height: 12px;
      background-position: -29px -183px;
    }

    &.solo {
      width: 12px;
      height: 12px;
      background-position: -43px -183px;
    }

    &.sommer {
      width: 12px;
      height: 12px;
      background-position: -57px -183px;
    }

    &.spectre {
      width: 12px;
      height: 12px;
      background-position: -71px -183px;
    }

    &.spyker {
      width: 12px;
      height: 12px;
      background-position: -85px -183px;
    }

    &.ssangyong {
      width: 12px;
      height: 12px;
      background-position: -99px -183px;
    }

    &.standard {
      width: 12px;
      height: 12px;
      background-position: -113px -183px;
    }

    &.steyr {
      width: 12px;
      height: 12px;
      background-position: -127px -183px;
    }

    &.streetscooter {
      width: 12px;
      height: 12px;
      background-position: -141px -183px;
    }

    &.subaru {
      width: 12px;
      height: 12px;
      background-position: -155px -183px;
    }

    &.suzuki {
      width: 12px;
      height: 12px;
      background-position: -169px -183px;
    }

    &.swm {
      width: 12px;
      height: 12px;
      background-position: -183px -183px;
    }

    &.sym {
      width: 12px;
      height: 12px;
      background-position: -197px -183px;
    }

    &.talbot {
      width: 12px;
      height: 12px;
      background-position: -1px -197px;
    }

    &.tata {
      width: 12px;
      height: 12px;
      background-position: -15px -197px;
    }

    &.tazzari {
      width: 12px;
      height: 12px;
      background-position: -29px -197px;
    }

    &.tesla {
      width: 12px;
      height: 12px;
      background-position: -43px -197px;
    }

    &.tgb {
      width: 12px;
      height: 12px;
      background-position: -57px -197px;
    }

    &.thnk {
      width: 12px;
      height: 12px;
      background-position: -71px -197px;
    }

    &.tomos {
      width: 12px;
      height: 12px;
      background-position: -85px -197px;
    }

    &.tornax {
      width: 12px;
      height: 12px;
      background-position: -99px -197px;
    }

    &.toyota {
      width: 12px;
      height: 12px;
      background-position: -113px -197px;
    }

    &.trabant {
      width: 12px;
      height: 12px;
      background-position: -127px -197px;
    }

    &.triumph {
      width: 12px;
      height: 12px;
      background-position: -141px -197px;
    }

    &.tvr {
      width: 12px;
      height: 12px;
      background-position: -155px -197px;
    }

    &.uaz {
      width: 12px;
      height: 12px;
      background-position: -169px -197px;
    }

    &.umm {
      width: 12px;
      height: 12px;
      background-position: -183px -197px;
    }

    &.vauxhall {
      width: 12px;
      height: 12px;
      background-position: -197px -197px;
    }

    &.vector {
      width: 12px;
      height: 12px;
      background-position: -211px -1px;
    }

    &.vespa {
      width: 12px;
      height: 12px;
      background-position: -211px -15px;
    }

    &.victory {
      width: 12px;
      height: 12px;
      background-position: -211px -29px;
    }

    &.volkswagen {
      width: 12px;
      height: 12px;
      background-position: -211px -43px;
    }

    &.volvo {
      width: 12px;
      height: 12px;
      background-position: -211px -57px;
    }

    &.vw {
      width: 12px;
      height: 12px;
      background-position: -211px -71px;
    }

    &.vw-svw {
      width: 12px;
      height: 12px;
      background-position: -211px -85px;
    }

    &.wartburg {
      width: 12px;
      height: 12px;
      background-position: -211px -99px;
    }

    &.westfield {
      width: 12px;
      height: 12px;
      background-position: -211px -113px;
    }

    &.wiesmann {
      width: 12px;
      height: 12px;
      background-position: -211px -127px;
    }

    &.wolseley {
      width: 12px;
      height: 12px;
      background-position: -211px -141px;
    }

    &.yamaha {
      width: 12px;
      height: 12px;
      background-position: -211px -155px;
    }

    &.yulon {
      width: 12px;
      height: 12px;
      background-position: -211px -169px;
    }

    &.yugozastava {
      width: 12px;
      height: 12px;
      background-position: -211px -183px;
    }

    &.zaz {
      width: 12px;
      height: 12px;
      background-position: -211px -197px;
    }

    &.zero {
      width: 12px;
      height: 12px;
      background-position: -1px -211px;
    }

    &.zuendapp {
      width: 12px;
      height: 12px;
      background-position: -15px -211px;
    }
  }

  &.large {
    width: 40px;
    height: 40px;
    background-image: url(@large);
    background-position: -1px -1px;

    &.abarth {
      width: 40px;
      height: 40px;
      background-position: -43px -1px;
    }

    &.ac {
      width: 40px;
      height: 40px;
      background-position: -85px -1px;
    }

    &.acura {
      width: 40px;
      height: 40px;
      background-position: -127px -1px;
    }

    &.aeon {
      width: 40px;
      height: 40px;
      background-position: -169px -1px;
    }

    &.aixam {
      width: 40px;
      height: 40px;
      background-position: -211px -1px;
    }

    &.alfaromeo {
      width: 40px;
      height: 40px;
      background-position: -253px -1px;
    }

    &.alpina {
      width: 40px;
      height: 40px;
      background-position: -295px -1px;
    }

    &.alpine {
      width: 40px;
      height: 40px;
      background-position: -337px -1px;
    }

    &.amc {
      width: 40px;
      height: 40px;
      background-position: -379px -1px;
    }

    &.aprilia {
      width: 40px;
      height: 40px;
      background-position: -421px -1px;
    }

    &.aro {
      width: 40px;
      height: 40px;
      background-position: -463px -1px;
    }

    &.artega {
      width: 40px;
      height: 40px;
      background-position: -505px -1px;
    }

    &.asiamotors {
      width: 40px;
      height: 40px;
      background-position: -547px -1px;
    }

    &.astonmartin {
      width: 40px;
      height: 40px;
      background-position: -589px -1px;
    }

    &.audi {
      width: 40px;
      height: 40px;
      background-position: -1px -43px;
    }

    &.austin {
      width: 40px;
      height: 40px;
      background-position: -43px -43px;
    }

    &.austinhealey {
      width: 40px;
      height: 40px;
      background-position: -85px -43px;
    }

    &.autobianchi {
      width: 40px;
      height: 40px;
      background-position: -127px -43px;
    }

    &.autounion {
      width: 40px;
      height: 40px;
      background-position: -169px -43px;
    }

    &.baojun {
      width: 40px;
      height: 40px;
      background-position: -211px -43px;
    }

    &.barkas {
      width: 40px;
      height: 40px;
      background-position: -253px -43px;
    }

    &.bedford {
      width: 40px;
      height: 40px;
      background-position: -295px -43px;
    }

    &.beeline {
      width: 40px;
      height: 40px;
      background-position: -337px -43px;
    }

    &.benelli {
      width: 40px;
      height: 40px;
      background-position: -379px -43px;
    }

    &.bentley {
      width: 40px;
      height: 40px;
      background-position: -421px -43px;
    }

    &.bertone {
      width: 40px;
      height: 40px;
      background-position: -463px -43px;
    }

    &.besturn {
      width: 40px;
      height: 40px;
      background-position: -505px -43px;
    }

    &.beta {
      width: 40px;
      height: 40px;
      background-position: -547px -43px;
    }

    &.bimota {
      width: 40px;
      height: 40px;
      background-position: -589px -43px;
    }

    &.bitter {
      width: 40px;
      height: 40px;
      background-position: -1px -85px;
    }

    &.bmw {
      width: 40px;
      height: 40px;
      background-position: -43px -85px;
    }

    &.boatian {
      width: 40px;
      height: 40px;
      background-position: -85px -85px;
    }

    &.bond {
      width: 40px;
      height: 40px;
      background-position: -127px -85px;
    }

    &.borgward {
      width: 40px;
      height: 40px;
      background-position: -169px -85px;
    }

    &.bristol {
      width: 40px;
      height: 40px;
      background-position: -211px -85px;
    }

    &.buell {
      width: 40px;
      height: 40px;
      background-position: -253px -85px;
    }

    &.bugatti {
      width: 40px;
      height: 40px;
      background-position: -295px -85px;
    }

    &.buick {
      width: 40px;
      height: 40px;
      background-position: -337px -85px;
    }

    &.cadillac {
      width: 40px;
      height: 40px;
      background-position: -379px -85px;
    }

    &.cagiva {
      width: 40px;
      height: 40px;
      background-position: -421px -85px;
    }

    &.callaway {
      width: 40px;
      height: 40px;
      background-position: -463px -85px;
    }

    &.carbodies {
      width: 40px;
      height: 40px;
      background-position: -505px -85px;
    }

    &.caterham {
      width: 40px;
      height: 40px;
      background-position: -547px -85px;
    }

    &.cfmoto {
      width: 40px;
      height: 40px;
      background-position: -589px -85px;
    }

    &.checker {
      width: 40px;
      height: 40px;
      background-position: -1px -127px;
    }

    &.chevroletusa {
      width: 40px;
      height: 40px;
      background-position: -43px -127px;
    }

    &.chrysler {
      width: 40px;
      height: 40px;
      background-position: -85px -127px;
    }

    &.citroen {
      width: 40px;
      height: 40px;
      background-position: -127px -127px;
    }

    &.corvette {
      width: 40px;
      height: 40px;
      background-position: -169px -127px;
    }

    &.dacia {
      width: 40px;
      height: 40px;
      background-position: -211px -127px;
    }

    &.daelim {
      width: 40px;
      height: 40px;
      background-position: -253px -127px;
    }

    &.daewoochevrolet {
      width: 40px;
      height: 40px;
      background-position: -295px -127px;
    }

    &.daf {
      width: 40px;
      height: 40px;
      background-position: -337px -127px;
    }

    &.daihatsu {
      width: 40px;
      height: 40px;
      background-position: -379px -127px;
    }

    &.daimler {
      width: 40px;
      height: 40px;
      background-position: -421px -127px;
    }

    &.dallas {
      width: 40px;
      height: 40px;
      background-position: -463px -127px;
    }

    &.delorean {
      width: 40px;
      height: 40px;
      background-position: -505px -127px;
    }

    &.derbi {
      width: 40px;
      height: 40px;
      background-position: -547px -127px;
    }

    &.detomaso {
      width: 40px;
      height: 40px;
      background-position: -589px -127px;
    }

    &.diahatsu {
      width: 40px;
      height: 40px;
      background-position: -1px -169px;
    }

    &.dodge {
      width: 40px;
      height: 40px;
      background-position: -43px -169px;
    }

    &.dodge-ram {
      width: 40px;
      height: 40px;
      background-position: -85px -169px;
    }

    &.ds {
      width: 40px;
      height: 40px;
      background-position: -127px -169px;
    }

    &.ducati {
      width: 40px;
      height: 40px;
      background-position: -169px -169px;
    }

    &.eagle {
      width: 40px;
      height: 40px;
      background-position: -211px -169px;
    }

    &.ecm {
      width: 40px;
      height: 40px;
      background-position: -253px -169px;
    }

    &.emc {
      width: 40px;
      height: 40px;
      background-position: -295px -169px;
    }

    &.fantic {
      width: 40px;
      height: 40px;
      background-position: -337px -169px;
    }

    &.ferrari {
      width: 40px;
      height: 40px;
      background-position: -379px -169px;
    }

    &.fiat {
      width: 40px;
      height: 40px;
      background-position: -421px -169px;
    }

    &.fisker {
      width: 40px;
      height: 40px;
      background-position: -463px -169px;
    }

    &.ford {
      width: 40px;
      height: 40px;
      background-position: -505px -169px;
    }

    &.fordotosan {
      width: 40px;
      height: 40px;
      background-position: -547px -169px;
    }

    &.fordusa {
      width: 40px;
      height: 40px;
      background-position: -589px -169px;
    }

    &.fso {
      width: 40px;
      height: 40px;
      background-position: -1px -211px;
    }

    &.gasgas {
      width: 40px;
      height: 40px;
      background-position: -43px -211px;
    }

    &.gaz {
      width: 40px;
      height: 40px;
      background-position: -85px -211px;
    }

    &.geely {
      width: 40px;
      height: 40px;
      background-position: -127px -211px;
    }

    &.generic {
      width: 40px;
      height: 40px;
      background-position: -169px -211px;
    }

    &.geo {
      width: 40px;
      height: 40px;
      background-position: -211px -211px;
    }

    &.gilera {
      width: 40px;
      height: 40px;
      background-position: -253px -211px;
    }

    &.ginetta {
      width: 40px;
      height: 40px;
      background-position: -295px -211px;
    }

    &.glas {
      width: 40px;
      height: 40px;
      background-position: -337px -211px;
    }

    &.gmc {
      width: 40px;
      height: 40px;
      background-position: -379px -211px;
    }

    &.harleydavidsonmc {
      width: 40px;
      height: 40px;
      background-position: -421px -211px;
    }

    &.hercules {
      width: 40px;
      height: 40px;
      background-position: -463px -211px;
    }

    &.hindustan {
      width: 40px;
      height: 40px;
      background-position: -505px -211px;
    }

    &.hmracing {
      width: 40px;
      height: 40px;
      background-position: -547px -211px;
    }

    &.hobbycar {
      width: 40px;
      height: 40px;
      background-position: -589px -211px;
    }

    &.holden {
      width: 40px;
      height: 40px;
      background-position: -1px -253px;
    }

    &.honda {
      width: 40px;
      height: 40px;
      background-position: -43px -253px;
    }

    &.hummer {
      width: 40px;
      height: 40px;
      background-position: -85px -253px;
    }

    &.husaberg {
      width: 40px;
      height: 40px;
      background-position: -127px -253px;
    }

    &.husqvarna {
      width: 40px;
      height: 40px;
      background-position: -169px -253px;
    }

    &.hyosung {
      width: 40px;
      height: 40px;
      background-position: -211px -253px;
    }

    &.hyundai {
      width: 40px;
      height: 40px;
      background-position: -253px -253px;
    }

    &.indian {
      width: 40px;
      height: 40px;
      background-position: -295px -253px;
    }

    &.indigo {
      width: 40px;
      height: 40px;
      background-position: -337px -253px;
    }

    &.infiniti {
      width: 40px;
      height: 40px;
      background-position: -379px -253px;
    }

    &.innocenti {
      width: 40px;
      height: 40px;
      background-position: -421px -253px;
    }

    &.irmscher {
      width: 40px;
      height: 40px;
      background-position: -463px -253px;
    }

    &.isdera {
      width: 40px;
      height: 40px;
      background-position: -505px -253px;
    }

    &.ish {
      width: 40px;
      height: 40px;
      background-position: -547px -253px;
    }

    &.isuzu {
      width: 40px;
      height: 40px;
      background-position: -589px -253px;
    }

    &.italjet {
      width: 40px;
      height: 40px;
      background-position: -1px -295px;
    }

    &.iveco {
      width: 40px;
      height: 40px;
      background-position: -43px -295px;
    }

    &.jaguar {
      width: 40px;
      height: 40px;
      background-position: -85px -295px;
    }

    &.jawa {
      width: 40px;
      height: 40px;
      background-position: -127px -295px;
    }

    &.jeep {
      width: 40px;
      height: 40px;
      background-position: -169px -295px;
    }

    &.jensen {
      width: 40px;
      height: 40px;
      background-position: -211px -295px;
    }

    &.kawasaki {
      width: 40px;
      height: 40px;
      background-position: -253px -295px;
    }

    &.kia {
      width: 40px;
      height: 40px;
      background-position: -295px -295px;
    }

    &.kreidler {
      width: 40px;
      height: 40px;
      background-position: -337px -295px;
    }

    &.ksr {
      width: 40px;
      height: 40px;
      background-position: -379px -295px;
    }

    &.ktm {
      width: 40px;
      height: 40px;
      background-position: -421px -295px;
    }

    &.kymco {
      width: 40px;
      height: 40px;
      background-position: -463px -295px;
    }

    &.lada {
      width: 40px;
      height: 40px;
      background-position: -505px -295px;
    }

    &.lamborghini {
      width: 40px;
      height: 40px;
      background-position: -547px -295px;
    }

    &.lancia {
      width: 40px;
      height: 40px;
      background-position: -589px -295px;
    }

    &.landrover {
      width: 40px;
      height: 40px;
      background-position: -1px -337px;
    }

    &.landwind-jmc {
      width: 40px;
      height: 40px;
      background-position: -43px -337px;
    }

    &.laverda {
      width: 40px;
      height: 40px;
      background-position: -85px -337px;
    }

    &.leylanddafldv {
      width: 40px;
      height: 40px;
      background-position: -127px -337px;
    }

    &.lexus {
      width: 40px;
      height: 40px;
      background-position: -169px -337px;
    }

    &.ligier {
      width: 40px;
      height: 40px;
      background-position: -211px -337px;
    }

    &.lincoln {
      width: 40px;
      height: 40px;
      background-position: -253px -337px;
    }

    &.lml {
      width: 40px;
      height: 40px;
      background-position: -295px -337px;
    }

    &.lotus {
      width: 40px;
      height: 40px;
      background-position: -337px -337px;
    }

    &.lti {
      width: 40px;
      height: 40px;
      background-position: -379px -337px;
    }

    &.mahindra {
      width: 40px;
      height: 40px;
      background-position: -421px -337px;
    }

    &.maico {
      width: 40px;
      height: 40px;
      background-position: -463px -337px;
    }

    &.malaguti {
      width: 40px;
      height: 40px;
      background-position: -505px -337px;
    }

    &.marcos {
      width: 40px;
      height: 40px;
      background-position: -547px -337px;
    }

    &.maserati {
      width: 40px;
      height: 40px;
      background-position: -589px -337px;
    }

    &.maybach {
      width: 40px;
      height: 40px;
      background-position: -1px -379px;
    }

    &.mazda {
      width: 40px;
      height: 40px;
      background-position: -43px -379px;
    }

    &.mbk {
      width: 40px;
      height: 40px;
      background-position: -85px -379px;
    }

    &.mclaren {
      width: 40px;
      height: 40px;
      background-position: -127px -379px;
    }

    &.mega {
      width: 40px;
      height: 40px;
      background-position: -169px -379px;
    }

    &.mercedes,
    &.mercedesbenz,
    &.mercedes-benz  {
      width: 40px;
      height: 40px;
      background-position: -211px -379px;
    }

    &.mercury {
      width: 40px;
      height: 40px;
      background-position: -253px -379px;
    }

    &.metrocab {
      width: 40px;
      height: 40px;
      background-position: -295px -379px;
    }

    &.mg {
      width: 40px;
      height: 40px;
      background-position: -337px -379px;
    }

    &.middlebridge {
      width: 40px;
      height: 40px;
      background-position: -379px -379px;
    }

    &.minelli {
      width: 40px;
      height: 40px;
      background-position: -421px -379px;
    }

    &.mini {
      width: 40px;
      height: 40px;
      background-position: -463px -379px;
    }

    &.mitsubishi {
      width: 40px;
      height: 40px;
      background-position: -505px -379px;
    }

    &.mitsuoka {
      width: 40px;
      height: 40px;
      background-position: -547px -379px;
    }

    &.morgan {
      width: 40px;
      height: 40px;
      background-position: -589px -379px;
    }

    &.morris {
      width: 40px;
      height: 40px;
      background-position: -1px -421px;
    }

    &.moskvich {
      width: 40px;
      height: 40px;
      background-position: -43px -421px;
    }

    &.motobi {
      width: 40px;
      height: 40px;
      background-position: -85px -421px;
    }

    &.motoguzzimc {
      width: 40px;
      height: 40px;
      background-position: -127px -421px;
    }

    &.motomorinimc {
      width: 40px;
      height: 40px;
      background-position: -169px -421px;
    }

    &.mvagusta {
      width: 40px;
      height: 40px;
      background-position: -211px -421px;
    }

    &.mz {
      width: 40px;
      height: 40px;
      background-position: -253px -421px;
    }

    &.nissan {
      width: 40px;
      height: 40px;
      background-position: -295px -421px;
    }

    &.nsu {
      width: 40px;
      height: 40px;
      background-position: -337px -421px;
    }

    &.oldsmobile {
      width: 40px;
      height: 40px;
      background-position: -379px -421px;
    }

    &.oltcit {
      width: 40px;
      height: 40px;
      background-position: -421px -421px;
    }

    &.opel {
      width: 40px;
      height: 40px;
      background-position: -463px -421px;
    }

    &.osca {
      width: 40px;
      height: 40px;
      background-position: -505px -421px;
    }

    &.panoz {
      width: 40px;
      height: 40px;
      background-position: -547px -421px;
    }

    &.panther {
      width: 40px;
      height: 40px;
      background-position: -589px -421px;
    }

    &.paykan {
      width: 40px;
      height: 40px;
      background-position: -1px -463px;
    }

    &.peugeot {
      width: 40px;
      height: 40px;
      background-position: -43px -463px;
    }

    &.pgo {
      width: 40px;
      height: 40px;
      background-position: -85px -463px;
    }

    &.piaggio {
      width: 40px;
      height: 40px;
      background-position: -127px -463px;
    }

    &.pininfarina {
      width: 40px;
      height: 40px;
      background-position: -169px -463px;
    }

    &.plymouth {
      width: 40px;
      height: 40px;
      background-position: -211px -463px;
    }

    &.pontiac {
      width: 40px;
      height: 40px;
      background-position: -253px -463px;
    }

    &.porsche {
      width: 40px;
      height: 40px;
      background-position: -295px -463px;
    }

    &.premier {
      width: 40px;
      height: 40px;
      background-position: -337px -463px;
    }

    &.proton {
      width: 40px;
      height: 40px;
      background-position: -379px -463px;
    }

    &.puch {
      width: 40px;
      height: 40px;
      background-position: -421px -463px;
    }

    &.quadro {
      width: 40px;
      height: 40px;
      background-position: -463px -463px;
    }

    &.ranger {
      width: 40px;
      height: 40px;
      background-position: -505px -463px;
    }

    &.raytonfissore {
      width: 40px;
      height: 40px;
      background-position: -547px -463px;
    }

    &.reliant {
      width: 40px;
      height: 40px;
      background-position: -589px -463px;
    }

    &.renault {
      width: 40px;
      height: 40px;
      background-position: -1px -505px;
    }

    &.renaulttrucks {
      width: 40px;
      height: 40px;
      background-position: -43px -505px;
    }

    &.rex {
      width: 40px;
      height: 40px;
      background-position: -85px -505px;
    }

    &.riley {
      width: 40px;
      height: 40px;
      background-position: -127px -505px;
    }

    &.rivero {
      width: 40px;
      height: 40px;
      background-position: -169px -505px;
    }

    &.rollsroyce {
      width: 40px;
      height: 40px;
      background-position: -211px -505px;
    }

    &.rover {
      width: 40px;
      height: 40px;
      background-position: -253px -505px;
    }

    &.ruf {
      width: 40px;
      height: 40px;
      background-position: -295px -505px;
    }

    &.saab {
      width: 40px;
      height: 40px;
      background-position: -337px -505px;
    }

    &.sachs {
      width: 40px;
      height: 40px;
      background-position: -379px -505px;
    }

    &.sanglas {
      width: 40px;
      height: 40px;
      background-position: -421px -505px;
    }

    &.santana {
      width: 40px;
      height: 40px;
      background-position: -463px -505px;
    }

    &.seat {
      width: 40px;
      height: 40px;
      background-position: -505px -505px;
    }

    &.shelby {
      width: 40px;
      height: 40px;
      background-position: -547px -505px;
    }

    &.simson {
      width: 40px;
      height: 40px;
      background-position: -589px -505px;
    }

    &.sipani {
      width: 40px;
      height: 40px;
      background-position: -1px -547px;
    }

    &.skoda {
      width: 40px;
      height: 40px;
      background-position: -43px -547px;
    }

    &.smart {
      width: 40px;
      height: 40px;
      background-position: -85px -547px;
    }

    &.solo {
      width: 40px;
      height: 40px;
      background-position: -127px -547px;
    }

    &.sommer {
      width: 40px;
      height: 40px;
      background-position: -169px -547px;
    }

    &.spectre {
      width: 40px;
      height: 40px;
      background-position: -211px -547px;
    }

    &.spyker {
      width: 40px;
      height: 40px;
      background-position: -253px -547px;
    }

    &.ssangyong {
      width: 40px;
      height: 40px;
      background-position: -295px -547px;
    }

    &.standard {
      width: 40px;
      height: 40px;
      background-position: -337px -547px;
    }

    &.steyr {
      width: 40px;
      height: 40px;
      background-position: -379px -547px;
    }

    &.streetscooter {
      width: 40px;
      height: 40px;
      background-position: -421px -547px;
    }

    &.subaru {
      width: 40px;
      height: 40px;
      background-position: -463px -547px;
    }

    &.suzuki {
      width: 40px;
      height: 40px;
      background-position: -505px -547px;
    }

    &.swm {
      width: 40px;
      height: 40px;
      background-position: -547px -547px;
    }

    &.sym {
      width: 40px;
      height: 40px;
      background-position: -589px -547px;
    }

    &.talbot {
      width: 40px;
      height: 40px;
      background-position: -1px -589px;
    }

    &.tata {
      width: 40px;
      height: 40px;
      background-position: -43px -589px;
    }

    &.tazzari {
      width: 40px;
      height: 40px;
      background-position: -85px -589px;
    }

    &.tesla {
      width: 40px;
      height: 40px;
      background-position: -127px -589px;
    }

    &.tgb {
      width: 40px;
      height: 40px;
      background-position: -169px -589px;
    }

    &.thnk {
      width: 40px;
      height: 40px;
      background-position: -211px -589px;
    }

    &.tomos {
      width: 40px;
      height: 40px;
      background-position: -253px -589px;
    }

    &.tornax {
      width: 40px;
      height: 40px;
      background-position: -295px -589px;
    }

    &.toyota {
      width: 40px;
      height: 40px;
      background-position: -337px -589px;
    }

    &.trabant {
      width: 40px;
      height: 40px;
      background-position: -379px -589px;
    }

    &.triumph {
      width: 40px;
      height: 40px;
      background-position: -421px -589px;
    }

    &.tvr {
      width: 40px;
      height: 40px;
      background-position: -463px -589px;
    }

    &.uaz {
      width: 40px;
      height: 40px;
      background-position: -505px -589px;
    }

    &.umm {
      width: 40px;
      height: 40px;
      background-position: -547px -589px;
    }

    &.vauxhall {
      width: 40px;
      height: 40px;
      background-position: -589px -589px;
    }

    &.vector {
      width: 40px;
      height: 40px;
      background-position: -631px -1px;
    }

    &.vespa {
      width: 40px;
      height: 40px;
      background-position: -631px -43px;
    }

    &.victory {
      width: 40px;
      height: 40px;
      background-position: -631px -85px;
    }

    &.volkswagen {
      width: 40px;
      height: 40px;
      background-position: -631px -127px;
    }

    &.volvo {
      width: 40px;
      height: 40px;
      background-position: -631px -169px;
    }

    &.vw {
      width: 40px;
      height: 40px;
      background-position: -631px -211px;
    }

    &.vw-svw {
      width: 40px;
      height: 40px;
      background-position: -631px -253px;
    }

    &.wartburg {
      width: 40px;
      height: 40px;
      background-position: -631px -295px;
    }

    &.westfield {
      width: 40px;
      height: 40px;
      background-position: -631px -337px;
    }

    &.wiesmann {
      width: 40px;
      height: 40px;
      background-position: -631px -379px;
    }

    &.wolseley {
      width: 40px;
      height: 40px;
      background-position: -631px -421px;
    }

    &.yamaha {
      width: 40px;
      height: 40px;
      background-position: -631px -463px;
    }

    &.yulon {
      width: 40px;
      height: 40px;
      background-position: -631px -505px;
    }

    &.yugozastava {
      width: 40px;
      height: 40px;
      background-position: -631px -547px;
    }

    &.zaz {
      width: 40px;
      height: 40px;
      background-position: -631px -589px;
    }

    &.zero {
      width: 40px;
      height: 40px;
      background-position: -1px -631px;
    }

    &.zuendapp {
      width: 40px;
      height: 40px;
      background-position: -43px -631px;
    }
  }

  &.massive {
    width: 90px;
    height: 90px;
    background-image: url(@massive);
    background-position: -1px -1px;

    &.abarth {
      width: 90px;
      height: 90px;
      background-position: -93px -1px;
    }

    &.ac {
      width: 90px;
      height: 90px;
      background-position: -185px -1px;
    }

    &.acura {
      width: 90px;
      height: 90px;
      background-position: -277px -1px;
    }

    &.aeon {
      width: 90px;
      height: 90px;
      background-position: -369px -1px;
    }

    &.aixam {
      width: 90px;
      height: 90px;
      background-position: -461px -1px;
    }

    &.alfaromeo {
      width: 90px;
      height: 90px;
      background-position: -553px -1px;
    }

    &.alpina {
      width: 90px;
      height: 90px;
      background-position: -645px -1px;
    }

    &.alpine {
      width: 90px;
      height: 90px;
      background-position: -737px -1px;
    }

    &.amc {
      width: 90px;
      height: 90px;
      background-position: -829px -1px;
    }

    &.aprilia {
      width: 90px;
      height: 90px;
      background-position: -921px -1px;
    }

    &.aro {
      width: 90px;
      height: 90px;
      background-position: -1013px -1px;
    }

    &.artega {
      width: 90px;
      height: 90px;
      background-position: -1105px -1px;
    }

    &.asiamotors {
      width: 90px;
      height: 90px;
      background-position: -1197px -1px;
    }

    &.astonmartin {
      width: 90px;
      height: 90px;
      background-position: -1289px -1px;
    }

    &.audi {
      width: 90px;
      height: 90px;
      background-position: -1px -93px;
    }

    &.austin {
      width: 90px;
      height: 90px;
      background-position: -93px -93px;
    }

    &.austinhealey {
      width: 90px;
      height: 90px;
      background-position: -185px -93px;
    }

    &.autobianchi {
      width: 90px;
      height: 90px;
      background-position: -277px -93px;
    }

    &.autounion {
      width: 90px;
      height: 90px;
      background-position: -369px -93px;
    }

    &.baojun {
      width: 90px;
      height: 90px;
      background-position: -461px -93px;
    }

    &.barkas {
      width: 90px;
      height: 90px;
      background-position: -553px -93px;
    }

    &.bedford {
      width: 90px;
      height: 90px;
      background-position: -645px -93px;
    }

    &.beeline {
      width: 90px;
      height: 90px;
      background-position: -737px -93px;
    }

    &.benelli {
      width: 90px;
      height: 90px;
      background-position: -829px -93px;
    }

    &.bentley {
      width: 90px;
      height: 90px;
      background-position: -921px -93px;
    }

    &.bertone {
      width: 90px;
      height: 90px;
      background-position: -1013px -93px;
    }

    &.besturn {
      width: 90px;
      height: 90px;
      background-position: -1105px -93px;
    }

    &.beta {
      width: 90px;
      height: 90px;
      background-position: -1197px -93px;
    }

    &.bimota {
      width: 90px;
      height: 90px;
      background-position: -1289px -93px;
    }

    &.bitter {
      width: 90px;
      height: 90px;
      background-position: -1px -185px;
    }

    &.bmw {
      width: 90px;
      height: 90px;
      background-position: -93px -185px;
    }

    &.boatian {
      width: 90px;
      height: 90px;
      background-position: -185px -185px;
    }

    &.bond {
      width: 90px;
      height: 90px;
      background-position: -277px -185px;
    }

    &.borgward {
      width: 90px;
      height: 90px;
      background-position: -369px -185px;
    }

    &.bristol {
      width: 90px;
      height: 90px;
      background-position: -461px -185px;
    }

    &.buell {
      width: 90px;
      height: 90px;
      background-position: -553px -185px;
    }

    &.bugatti {
      width: 90px;
      height: 90px;
      background-position: -645px -185px;
    }

    &.buick {
      width: 90px;
      height: 90px;
      background-position: -737px -185px;
    }

    &.cadillac {
      width: 90px;
      height: 90px;
      background-position: -829px -185px;
    }

    &.cagiva {
      width: 90px;
      height: 90px;
      background-position: -921px -185px;
    }

    &.callaway {
      width: 90px;
      height: 90px;
      background-position: -1013px -185px;
    }

    &.carbodies {
      width: 90px;
      height: 90px;
      background-position: -1105px -185px;
    }

    &.caterham {
      width: 90px;
      height: 90px;
      background-position: -1197px -185px;
    }

    &.cfmoto {
      width: 90px;
      height: 90px;
      background-position: -1289px -185px;
    }

    &.checker {
      width: 90px;
      height: 90px;
      background-position: -1px -277px;
    }

    &.chevroletusa {
      width: 90px;
      height: 90px;
      background-position: -93px -277px;
    }

    &.chrysler {
      width: 90px;
      height: 90px;
      background-position: -185px -277px;
    }

    &.citroen {
      width: 90px;
      height: 90px;
      background-position: -277px -277px;
    }

    &.corvette {
      width: 90px;
      height: 90px;
      background-position: -369px -277px;
    }

    &.dacia {
      width: 90px;
      height: 90px;
      background-position: -461px -277px;
    }

    &.daelim {
      width: 90px;
      height: 90px;
      background-position: -553px -277px;
    }

    &.daewoochevrolet {
      width: 90px;
      height: 90px;
      background-position: -645px -277px;
    }

    &.daf {
      width: 90px;
      height: 90px;
      background-position: -737px -277px;
    }

    &.daihatsu {
      width: 90px;
      height: 90px;
      background-position: -829px -277px;
    }

    &.daimler {
      width: 90px;
      height: 90px;
      background-position: -921px -277px;
    }

    &.dallas {
      width: 90px;
      height: 90px;
      background-position: -1013px -277px;
    }

    &.delorean {
      width: 90px;
      height: 90px;
      background-position: -1105px -277px;
    }

    &.derbi {
      width: 90px;
      height: 90px;
      background-position: -1197px -277px;
    }

    &.detomaso {
      width: 90px;
      height: 90px;
      background-position: -1289px -277px;
    }

    &.diahatsu {
      width: 90px;
      height: 90px;
      background-position: -1px -369px;
    }

    &.dodge {
      width: 90px;
      height: 90px;
      background-position: -93px -369px;
    }

    &.dodge-ram {
      width: 90px;
      height: 90px;
      background-position: -185px -369px;
    }

    &.ds {
      width: 90px;
      height: 90px;
      background-position: -277px -369px;
    }

    &.ducati {
      width: 90px;
      height: 90px;
      background-position: -369px -369px;
    }

    &.eagle {
      width: 90px;
      height: 90px;
      background-position: -461px -369px;
    }

    &.ecm {
      width: 90px;
      height: 90px;
      background-position: -553px -369px;
    }

    &.emc {
      width: 90px;
      height: 90px;
      background-position: -645px -369px;
    }

    &.fantic {
      width: 90px;
      height: 90px;
      background-position: -737px -369px;
    }

    &.ferrari {
      width: 90px;
      height: 90px;
      background-position: -829px -369px;
    }

    &.fiat {
      width: 90px;
      height: 90px;
      background-position: -921px -369px;
    }

    &.fisker {
      width: 90px;
      height: 90px;
      background-position: -1013px -369px;
    }

    &.ford {
      width: 90px;
      height: 90px;
      background-position: -1105px -369px;
    }

    &.fordotosan {
      width: 90px;
      height: 90px;
      background-position: -1197px -369px;
    }

    &.fordusa {
      width: 90px;
      height: 90px;
      background-position: -1289px -369px;
    }

    &.fso {
      width: 90px;
      height: 90px;
      background-position: -1px -461px;
    }

    &.gasgas {
      width: 90px;
      height: 90px;
      background-position: -93px -461px;
    }

    &.gaz {
      width: 90px;
      height: 90px;
      background-position: -185px -461px;
    }

    &.geely {
      width: 90px;
      height: 90px;
      background-position: -277px -461px;
    }

    &.generic {
      width: 90px;
      height: 90px;
      background-position: -369px -461px;
    }

    &.geo {
      width: 90px;
      height: 90px;
      background-position: -461px -461px;
    }

    &.gilera {
      width: 90px;
      height: 90px;
      background-position: -553px -461px;
    }

    &.ginetta {
      width: 90px;
      height: 90px;
      background-position: -645px -461px;
    }

    &.glas {
      width: 90px;
      height: 90px;
      background-position: -737px -461px;
    }

    &.gmc {
      width: 90px;
      height: 90px;
      background-position: -829px -461px;
    }

    &.harleydavidsonmc {
      width: 90px;
      height: 90px;
      background-position: -921px -461px;
    }

    &.hercules {
      width: 90px;
      height: 90px;
      background-position: -1013px -461px;
    }

    &.hindustan {
      width: 90px;
      height: 90px;
      background-position: -1105px -461px;
    }

    &.hmracing {
      width: 90px;
      height: 90px;
      background-position: -1197px -461px;
    }

    &.hobbycar {
      width: 90px;
      height: 90px;
      background-position: -1289px -461px;
    }

    &.holden {
      width: 90px;
      height: 90px;
      background-position: -1px -553px;
    }

    &.honda {
      width: 90px;
      height: 90px;
      background-position: -93px -553px;
    }

    &.hummer {
      width: 90px;
      height: 90px;
      background-position: -185px -553px;
    }

    &.husaberg {
      width: 90px;
      height: 90px;
      background-position: -277px -553px;
    }

    &.husqvarna {
      width: 90px;
      height: 90px;
      background-position: -369px -553px;
    }

    &.hyosung {
      width: 90px;
      height: 90px;
      background-position: -461px -553px;
    }

    &.hyundai {
      width: 90px;
      height: 90px;
      background-position: -553px -553px;
    }

    &.indian {
      width: 90px;
      height: 90px;
      background-position: -645px -553px;
    }

    &.indigo {
      width: 90px;
      height: 90px;
      background-position: -737px -553px;
    }

    &.infiniti {
      width: 90px;
      height: 90px;
      background-position: -829px -553px;
    }

    &.innocenti {
      width: 90px;
      height: 90px;
      background-position: -921px -553px;
    }

    &.irmscher {
      width: 90px;
      height: 90px;
      background-position: -1013px -553px;
    }

    &.isdera {
      width: 90px;
      height: 90px;
      background-position: -1105px -553px;
    }

    &.ish {
      width: 90px;
      height: 90px;
      background-position: -1197px -553px;
    }

    &.isuzu {
      width: 90px;
      height: 90px;
      background-position: -1289px -553px;
    }

    &.italjet {
      width: 90px;
      height: 90px;
      background-position: -1px -645px;
    }

    &.iveco {
      width: 90px;
      height: 90px;
      background-position: -93px -645px;
    }

    &.jaguar {
      width: 90px;
      height: 90px;
      background-position: -185px -645px;
    }

    &.jawa {
      width: 90px;
      height: 90px;
      background-position: -277px -645px;
    }

    &.jeep {
      width: 90px;
      height: 90px;
      background-position: -369px -645px;
    }

    &.jensen {
      width: 90px;
      height: 90px;
      background-position: -461px -645px;
    }

    &.kawasaki {
      width: 90px;
      height: 90px;
      background-position: -553px -645px;
    }

    &.kia {
      width: 90px;
      height: 90px;
      background-position: -645px -645px;
    }

    &.kreidler {
      width: 90px;
      height: 90px;
      background-position: -737px -645px;
    }

    &.ksr {
      width: 90px;
      height: 90px;
      background-position: -829px -645px;
    }

    &.ktm {
      width: 90px;
      height: 90px;
      background-position: -921px -645px;
    }

    &.kymco {
      width: 90px;
      height: 90px;
      background-position: -1013px -645px;
    }

    &.lada {
      width: 90px;
      height: 90px;
      background-position: -1105px -645px;
    }

    &.lamborghini {
      width: 90px;
      height: 90px;
      background-position: -1197px -645px;
    }

    &.lancia {
      width: 90px;
      height: 90px;
      background-position: -1289px -645px;
    }

    &.landrover {
      width: 90px;
      height: 90px;
      background-position: -1px -737px;
    }

    &.landwind-jmc {
      width: 90px;
      height: 90px;
      background-position: -93px -737px;
    }

    &.laverda {
      width: 90px;
      height: 90px;
      background-position: -185px -737px;
    }

    &.leylanddafldv {
      width: 90px;
      height: 90px;
      background-position: -277px -737px;
    }

    &.lexus {
      width: 90px;
      height: 90px;
      background-position: -369px -737px;
    }

    &.ligier {
      width: 90px;
      height: 90px;
      background-position: -461px -737px;
    }

    &.lincoln {
      width: 90px;
      height: 90px;
      background-position: -553px -737px;
    }

    &.lml {
      width: 90px;
      height: 90px;
      background-position: -645px -737px;
    }

    &.lotus {
      width: 90px;
      height: 90px;
      background-position: -737px -737px;
    }

    &.lti {
      width: 90px;
      height: 90px;
      background-position: -829px -737px;
    }

    &.mahindra {
      width: 90px;
      height: 90px;
      background-position: -921px -737px;
    }

    &.maico {
      width: 90px;
      height: 90px;
      background-position: -1013px -737px;
    }

    &.malaguti {
      width: 90px;
      height: 90px;
      background-position: -1105px -737px;
    }

    &.marcos {
      width: 90px;
      height: 90px;
      background-position: -1197px -737px;
    }

    &.maserati {
      width: 90px;
      height: 90px;
      background-position: -1289px -737px;
    }

    &.maybach {
      width: 90px;
      height: 90px;
      background-position: -1px -829px;
    }

    &.mazda {
      width: 90px;
      height: 90px;
      background-position: -93px -829px;
    }

    &.mbk {
      width: 90px;
      height: 90px;
      background-position: -185px -829px;
    }

    &.mclaren {
      width: 90px;
      height: 90px;
      background-position: -277px -829px;
    }

    &.mega {
      width: 90px;
      height: 90px;
      background-position: -369px -829px;
    }

    &.mercedes,
    &.mercedesbenz,
    &.mercedes-benz  {
      width: 90px;
      height: 90px;
      background-position: -461px -829px;
    }

    &.mercury {
      width: 90px;
      height: 90px;
      background-position: -553px -829px;
    }

    &.metrocab {
      width: 90px;
      height: 90px;
      background-position: -645px -829px;
    }

    &.mg {
      width: 90px;
      height: 90px;
      background-position: -737px -829px;
    }

    &.middlebridge {
      width: 90px;
      height: 90px;
      background-position: -829px -829px;
    }

    &.minelli {
      width: 90px;
      height: 90px;
      background-position: -921px -829px;
    }

    &.mini {
      width: 90px;
      height: 90px;
      background-position: -1013px -829px;
    }

    &.mitsubishi {
      width: 90px;
      height: 90px;
      background-position: -1105px -829px;
    }

    &.mitsuoka {
      width: 90px;
      height: 90px;
      background-position: -1197px -829px;
    }

    &.morgan {
      width: 90px;
      height: 90px;
      background-position: -1289px -829px;
    }

    &.morris {
      width: 90px;
      height: 90px;
      background-position: -1px -921px;
    }

    &.moskvich {
      width: 90px;
      height: 90px;
      background-position: -93px -921px;
    }

    &.motobi {
      width: 90px;
      height: 90px;
      background-position: -185px -921px;
    }

    &.motoguzzimc {
      width: 90px;
      height: 90px;
      background-position: -277px -921px;
    }

    &.motomorinimc {
      width: 90px;
      height: 90px;
      background-position: -369px -921px;
    }

    &.mvagusta {
      width: 90px;
      height: 90px;
      background-position: -461px -921px;
    }

    &.mz {
      width: 90px;
      height: 90px;
      background-position: -553px -921px;
    }

    &.nissan {
      width: 90px;
      height: 90px;
      background-position: -645px -921px;
    }

    &.nsu {
      width: 90px;
      height: 90px;
      background-position: -737px -921px;
    }

    &.oldsmobile {
      width: 90px;
      height: 90px;
      background-position: -829px -921px;
    }

    &.oltcit {
      width: 90px;
      height: 90px;
      background-position: -921px -921px;
    }

    &.opel {
      width: 90px;
      height: 90px;
      background-position: -1013px -921px;
    }

    &.osca {
      width: 90px;
      height: 90px;
      background-position: -1105px -921px;
    }

    &.panoz {
      width: 90px;
      height: 90px;
      background-position: -1197px -921px;
    }

    &.panther {
      width: 90px;
      height: 90px;
      background-position: -1289px -921px;
    }

    &.paykan {
      width: 90px;
      height: 90px;
      background-position: -1px -1013px;
    }

    &.peugeot {
      width: 90px;
      height: 90px;
      background-position: -93px -1013px;
    }

    &.pgo {
      width: 90px;
      height: 90px;
      background-position: -185px -1013px;
    }

    &.piaggio {
      width: 90px;
      height: 90px;
      background-position: -277px -1013px;
    }

    &.pininfarina {
      width: 90px;
      height: 90px;
      background-position: -369px -1013px;
    }

    &.plymouth {
      width: 90px;
      height: 90px;
      background-position: -461px -1013px;
    }

    &.pontiac {
      width: 90px;
      height: 90px;
      background-position: -553px -1013px;
    }

    &.porsche {
      width: 90px;
      height: 90px;
      background-position: -645px -1013px;
    }

    &.premier {
      width: 90px;
      height: 90px;
      background-position: -737px -1013px;
    }

    &.proton {
      width: 90px;
      height: 90px;
      background-position: -829px -1013px;
    }

    &.puch {
      width: 90px;
      height: 90px;
      background-position: -921px -1013px;
    }

    &.quadro {
      width: 90px;
      height: 90px;
      background-position: -1013px -1013px;
    }

    &.ranger {
      width: 90px;
      height: 90px;
      background-position: -1105px -1013px;
    }

    &.raytonfissore {
      width: 90px;
      height: 90px;
      background-position: -1197px -1013px;
    }

    &.reliant {
      width: 90px;
      height: 90px;
      background-position: -1289px -1013px;
    }

    &.renault {
      width: 90px;
      height: 90px;
      background-position: -1px -1105px;
    }

    &.renaulttrucks {
      width: 90px;
      height: 90px;
      background-position: -93px -1105px;
    }

    &.rex {
      width: 90px;
      height: 90px;
      background-position: -185px -1105px;
    }

    &.riley {
      width: 90px;
      height: 90px;
      background-position: -277px -1105px;
    }

    &.rivero {
      width: 90px;
      height: 90px;
      background-position: -369px -1105px;
    }

    &.rollsroyce {
      width: 90px;
      height: 90px;
      background-position: -461px -1105px;
    }

    &.rover {
      width: 90px;
      height: 90px;
      background-position: -553px -1105px;
    }

    &.ruf {
      width: 90px;
      height: 90px;
      background-position: -645px -1105px;
    }

    &.saab {
      width: 90px;
      height: 90px;
      background-position: -737px -1105px;
    }

    &.sachs {
      width: 90px;
      height: 90px;
      background-position: -829px -1105px;
    }

    &.sanglas {
      width: 90px;
      height: 90px;
      background-position: -921px -1105px;
    }

    &.santana {
      width: 90px;
      height: 90px;
      background-position: -1013px -1105px;
    }

    &.seat {
      width: 90px;
      height: 90px;
      background-position: -1105px -1105px;
    }

    &.shelby {
      width: 90px;
      height: 90px;
      background-position: -1197px -1105px;
    }

    &.simson {
      width: 90px;
      height: 90px;
      background-position: -1289px -1105px;
    }

    &.sipani {
      width: 90px;
      height: 90px;
      background-position: -1px -1197px;
    }

    &.skoda {
      width: 90px;
      height: 90px;
      background-position: -93px -1197px;
    }

    &.smart {
      width: 90px;
      height: 90px;
      background-position: -185px -1197px;
    }

    &.solo {
      width: 90px;
      height: 90px;
      background-position: -277px -1197px;
    }

    &.sommer {
      width: 90px;
      height: 90px;
      background-position: -369px -1197px;
    }

    &.spectre {
      width: 90px;
      height: 90px;
      background-position: -461px -1197px;
    }

    &.spyker {
      width: 90px;
      height: 90px;
      background-position: -553px -1197px;
    }

    &.ssangyong {
      width: 90px;
      height: 90px;
      background-position: -645px -1197px;
    }

    &.standard {
      width: 90px;
      height: 90px;
      background-position: -737px -1197px;
    }

    &.steyr {
      width: 90px;
      height: 90px;
      background-position: -829px -1197px;
    }

    &.streetscooter {
      width: 90px;
      height: 90px;
      background-position: -921px -1197px;
    }

    &.subaru {
      width: 90px;
      height: 90px;
      background-position: -1013px -1197px;
    }

    &.suzuki {
      width: 90px;
      height: 90px;
      background-position: -1105px -1197px;
    }

    &.swm {
      width: 90px;
      height: 90px;
      background-position: -1197px -1197px;
    }

    &.sym {
      width: 90px;
      height: 90px;
      background-position: -1289px -1197px;
    }

    &.talbot {
      width: 90px;
      height: 90px;
      background-position: -1px -1289px;
    }

    &.tata {
      width: 90px;
      height: 90px;
      background-position: -93px -1289px;
    }

    &.tazzari {
      width: 90px;
      height: 90px;
      background-position: -185px -1289px;
    }

    &.tesla {
      width: 90px;
      height: 90px;
      background-position: -277px -1289px;
    }

    &.tgb {
      width: 90px;
      height: 90px;
      background-position: -369px -1289px;
    }

    &.thnk {
      width: 90px;
      height: 90px;
      background-position: -461px -1289px;
    }

    &.tomos {
      width: 90px;
      height: 90px;
      background-position: -553px -1289px;
    }

    &.tornax {
      width: 90px;
      height: 90px;
      background-position: -645px -1289px;
    }

    &.toyota {
      width: 90px;
      height: 90px;
      background-position: -737px -1289px;
    }

    &.trabant {
      width: 90px;
      height: 90px;
      background-position: -829px -1289px;
    }

    &.triumph {
      width: 90px;
      height: 90px;
      background-position: -921px -1289px;
    }

    &.tvr {
      width: 90px;
      height: 90px;
      background-position: -1013px -1289px;
    }

    &.uaz {
      width: 90px;
      height: 90px;
      background-position: -1105px -1289px;
    }

    &.umm {
      width: 90px;
      height: 90px;
      background-position: -1197px -1289px;
    }

    &.vauxhall {
      width: 90px;
      height: 90px;
      background-position: -1289px -1289px;
    }

    &.vector {
      width: 90px;
      height: 90px;
      background-position: -1381px -1px;
    }

    &.vespa {
      width: 90px;
      height: 90px;
      background-position: -1381px -93px;
    }

    &.victory {
      width: 90px;
      height: 90px;
      background-position: -1381px -185px;
    }

    &.volkswagen {
      width: 90px;
      height: 90px;
      background-position: -1381px -277px;
    }

    &.volvo {
      width: 90px;
      height: 90px;
      background-position: -1381px -369px;
    }

    &.vw {
      width: 90px;
      height: 90px;
      background-position: -1381px -461px;
    }

    &.vw-svw {
      width: 90px;
      height: 90px;
      background-position: -1381px -553px;
    }

    &.wartburg {
      width: 90px;
      height: 90px;
      background-position: -1381px -645px;
    }

    &.westfield {
      width: 90px;
      height: 90px;
      background-position: -1381px -737px;
    }

    &.wiesmann {
      width: 90px;
      height: 90px;
      background-position: -1381px -829px;
    }

    &.wolseley {
      width: 90px;
      height: 90px;
      background-position: -1381px -921px;
    }

    &.yamaha {
      width: 90px;
      height: 90px;
      background-position: -1381px -1013px;
    }

    &.yulon {
      width: 90px;
      height: 90px;
      background-position: -1381px -1105px;
    }

    &.yugozastava {
      width: 90px;
      height: 90px;
      background-position: -1381px -1197px;
    }

    &.zaz {
      width: 90px;
      height: 90px;
      background-position: -1381px -1289px;
    }

    &.zero {
      width: 90px;
      height: 90px;
      background-position: -1px -1381px;
    }

    &.zuendapp {
      width: 90px;
      height: 90px;
      background-position: -93px -1381px;
    }
  }
}

.html-preview {
  background-color: #fff;
  display: block;
  margin: auto;
  box-shadow: 0px 1px 2px 0 rgba(34, 36, 38, 0.15);
  border-radius: 0.28571429rem;
  border: 1px solid rgba(34, 36, 38, 0.15);
}
