.ellipsis {
  display: flex !important;
  > * {
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }
}

.capitalize {
  text-transform: capitalize;
}

.bold {
  font-weight: bold;
}

.cwhite {
  color: #fff;
}

.cgrey {
  color: #444 !important;
}

.no-underline {
  text-decoration: none !important;
}

.overflow-ellipsis {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}
.text-right{
  text-align:right;
}

.break-word {
  overflow-wrap: break-word;
}

.text-left{
  text-align:left;
}
