/*******************************
         Site Overrides
*******************************/
.ui.left.visible.sidebar {
  box-shadow: none;
  overflow: visible !important;
}

// Main navigation
.ui.left.left.sidebar-menu .sidebar-menu-item .sidebar-menu-item-border {
  background: var(--mainColour);
  width: 5px;
  height: 100%;
  position: absolute;
  display: none;
  top: 0;
  left: 0;
}

.ui.left.visible.sidebar {
  .sidebar-chevron {
    border-top: 1px solid @borderColor;
    i.icon {
      float: right;
      color: @textColor;
    }
    .powered-by {
      position: absolute;

      svg {
        height: 10px;
        width: 60px;
        float: left;
        margin: 3px 5px 0 0;
      }
    }
  }
  .sidebar-menu-item {
    text-transform: uppercase;
    font-size: 1rem;
    color: @textColor;
    display: flex;
    align-items: center;

    &-title {
      width: calc(100% - 2.7em);
      display: inline-flex;
      order: 2;
    }
    & > i.icon {
      display: inline-flex;
      order: 1;
      margin: 0 1em 0 0.5em;

      &.dropdown {
        position: absolute;
        right: 10px;
        margin: 0 -0.5em 0 0em;
      }
    }
    .sidebar-menu-item-border {
      background: var(--mainColour);
      width: 5px;
      height: 100%;
      position: absolute;
      display: none;
      top: 0;
      left: 0;
    }
    .dropdown.icon {
      display: none;
    }
    &:hover {
      background: @backgroundHoverColor;
    }
    &:hover,
    &.active {
      .sidebar-menu-item-border {
        display: block;
      }
    }
  }
  .sidebar-menu-toggle {
    position: absolute;
    top: 2px;
    right: 0px;
  }

  .sidebar-menu-open-child {
     font-size: small;
     text-indent: 0.7em;
     background: @backgroundHoverColor;

     &:hover,
     &.active {
      color: @dropdownSelectionHoverTextColor;
      background: @dropdownSelectionHoverColor;
     }

     &.active {
      font-weight:bold;
     }

     .sidebar-menu-item-border {
       background: var(--mainColour);
       width: 5px;
       height: 100%;
       position: absolute;
       display: block;
       top: 0;
       left: 0;
     }
  }
}
