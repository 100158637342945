.content-pusher {
  height: calc(100vh - 3.8rem);
  overflow-y: auto !important;
  transition: margin 300ms;
  padding: 1rem;
  margin-left: 60px;

  &.open {
    margin-left: 200px;
  }

  &.sidebar-hidden {
    margin-left: 0;
  }
}

.snackbar_menu-expanded{
  transform: translate(220px, 0);
}

.snackbar_menu-collapsed{
  transform: translate(60px, 0);
}
