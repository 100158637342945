/*******************************
        User Overrides
*******************************/
.ui.selection.dropdown {
    border: 1px solid #dedede;
}

.ui.selection.dropdown > .search.icon {
    top: .5em;
}

.ui.selection.dropdown:hover {
    border-color: @inputHoverBorderColor;
}

.ui.selection.dropdown.primary {
  border-color: @primaryBorderColor;
  background-color: @primaryBackgroundColor;
}

.dropdown-menu-ui.disabled {
  border-color: @disabledColor !important;
  .text {
    color: @solidWhiteBorderColor !important;
  }
}
