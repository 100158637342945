.overflow-visible {
  overflow: visible !important;
}

.overflow-hidden {
  overflow: hidden !important;
}

.overflow-auto {
  overflow: auto !important;
}

.text-overflow-hidden {
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}

.vehicle-indicator {
  display: flex;
  align-items: center;
}

.clearfix::after {
  content: "";
  clear: both;
  display: table;
}
