.colorpicker {
  &__sketch {
    box-shadow: none !important;
    padding: 0 !important;
  }

  .color {
    width: 36px;
    height: 28px;
  }

  .swatch {
    padding: 5px;
    background: #fff;
    border-radius: 0.28571429rem;
    box-shadow: 0 0 0 1px rgba(0, 0, 0, 0.1);
    display: inline-block;
    cursor: pointer;
  }

  .popover {
    position: absolute;
    z-index: 2;
  }

  .cover {
    position: sticky;
    top: 0px;
    right: 0px;
    bottom: 0px;
    left: 0px;
  }
}
