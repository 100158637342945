.vehicle-validation-dropdown {
  position: absolute !important;
  z-index: 3 !important;
  width: 80% !important;
  margin-top: 0 !important;
  border-top: none !important;
}

.vehicle-validation-detailsgrid {
  padding-right: 4rem !important;

  table {
    width: 100% !important;
  }
}
