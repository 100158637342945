// Semantic will 'invert' all loaders when the dimmer is active,
// this is not needed for modals, so this will reset the loader

.ui.dimmer.modals .ui.loader:before {
  border-color: rgba(0, 0, 0, 0.1);
}

.ui.dimmer.modals .ui.loader:after {
  border-color: #767676 transparent transparent;
}
