.rbc-past {
  background-color: #eee;
}

// We need to overwrite the past bg color so we need to import the styles after we set the background color of the rbc-past variable
@import './styles';

@event-color: var(--mainColourText);

.rbc-agenda-view {
  flex: 1 0;
}

.rbc-event {
  background-color: var(--mainColour);
  border-radius: 0;
  outline: none;
  display:block;

  &.rbc-selected{
    background-color: var(--mainColour);
  }
}

.rbc-month-view {
  flex: 1 0;
}

.rbc-date-cell {
  flex: 1 1;
}

.rbc-month-row {
  flex: 1 1 !important;
  flex-basis: initial;
}

.rbc-calendar {
  .buttons > .ui.button:hover {
    background: rgba(0, 0, 0, 0.03) !important;
  }
}

.rbc-header {
  flex: 1 1;
}

.rbc-event-content {
  flex: 1 1;
}

.rbc-time-view-resources {
  .rbc-header,
  .rbc-day-bg {
    width: 140px;
    flex: 1 1;
  }
}

.rbc-time-content {
  flex: 1 0;

  > .rbc-day-slot {
    width: initial;
    flex: 1 1;
  }
}
