.ui .card img.ui.fit {
  object-fit: contain;
  height: 175px;
  background-color: white;

  &[src$="png"] { // don't add white background to pngs
    background-color: transparent;
  }
}

.ui.modal > .actions.left {
  text-align: left;
}

.product-detail-supplier-logo {
  float: right;
  height: 30px;
}