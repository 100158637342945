/*******************************
        User Overrides
*******************************/

.lease-company-popup.ui.bottom.left.popup:before {
  left: .5em;
}
.calendar-help-popup.ui.bottom.right.popup:before {
  right: .7em;
}

.messages__popup.ui.bottom.right.popup:before{
  right: .4em;
}
.timeregistration.ui.bottom.right:before {
  right: .2rem;
}
