.inspection-aspects-table {
  &__defect-text {
    font-weight: bold;
    font-style: italic;
  }

  &__defect-row {
    &--negative {
      background-color: @inputErrorBgColor;

      & .inspection-aspects-table__defect-action {
        font-style: italic;
        font-weight: bold;
        color: @inputErrorBorderColor;
      }
    }
  }
}

.inspection-defect-section {
  &__text {
    font-style: italic;
    font-weight: bold;
  }
}


.finish-apk-modal__message {
  position: sticky;
  z-index: 10;
  top: -15px;
  bottom: 10px;
  padding: 10px 10px;
  background-color: @white;
}
