td {
  overflow-wrap: break-word;
  // max-width: 0; // makes sure a td doesn't stretch a table beyond page width
}

td.no-break {
  overflow-wrap: initial;
  max-width: initial; // make sure the cell stretches to fit all content.
}

.italic {
  font-style: italic;
}

.text-small {
  font-size: 90%;
}
