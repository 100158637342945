.wrapping-tabs {
  .ui.tabular.menu {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap-reverse;

    .item {
      max-width: 20%;
      word-break: break-word;
    }
  }
}
