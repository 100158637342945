#calendar-nav-buttons {
  .ui.basic.buttons{
    background:@white !important;
  }
  margin-top: 0.5rem !important;
}

.calendar-table {
  min-height: 500px;
  border: none !important;

  .capacity-used {
    color: #009900;
    font-weight: normal;
  }

  .capacity-total {
    font-weight: normal;
  }

  .highlight {
    div {
      background: #c2c2c2 !important;
      color: white;
    }
  }

  .disabled {
    color: darkgray;
  }

  .day-label {
    display: inline-block;
    padding: 0 @spacer;
    height: 29px;
    line-height: 29px;
    white-space: nowrap;
  }

  .number {
    .button {
      font-size: 1rem;
      padding: 1px 2px 2px 2px !important;
    }
  }

  .ui.grid.calendar-item {
    height: 95px;
    background: white;
    max-width: 310px;
    margin: 0 auto !important;
    border-bottom: 1px solid rgba(34, 36, 38, 0.1);
    border-left: 1px solid rgba(34, 36, 38, 0.1);
    white-space: nowrap;
    z-index: 1;
    position: relative;
  }

  .border-bottom {
    border-bottom: 1px solid rgba(34, 36, 38, 0.1);
  }

  .border-right {
    border-right: 1px solid rgba(34, 36, 38, 0.1);
  }

  .border-top {
    border-top: 1px solid rgba(34, 36, 38, 0.1);
  }

  .border-left {
    border-left: 1px solid rgba(34, 36, 38, 0.1);
  }

  .activitywidth {
    width: @spacer;
  }

  .day-closed-description {
    padding: 10px;
    text-align: center;
  }

  .day-closed {
    background: rgba(255, 0, 0, 0.15);
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
  }

  td {
    position: relative;
  }
}

.drag-nav-left {
  position: absolute;
  left: 0;
  top: 0;
  height: 100%;
  width: 14px;
  background-color: white;
}

.drag-nav-right {
  position: absolute;
  right: 0;
  top: 0;
  height: 100%;
  width: 14px;
  background-color: white;
}

.calendar-actions {
  &.ui.form {
    position: initial;
    max-width: initial;

    .field .ui.input {
      width: initial;
    }
  }
}

.calendar-help-popup {
  max-width: 750px !important;

  .column > .grid {
    min-height: 50px;

    .ui.label > .icon {
      margin: 0;
    }
  }
}
