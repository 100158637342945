.h-masonry{
  &.grid{
    display: block !important;
  }
}


@media only screen and (min-width: 768px) {
  .h-masonry.grid {
    column-count: 2;
    column-gap: 0;
  }

  .ui.doubling.h-masonry.grid[class*="two column"] > .column {
    width: 100% !important;
  }
}

@media only screen and (min-width: 992px) {
  .h-masonry.grid {
    column-count: 2;
  }
}
