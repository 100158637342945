.cbgms-carousel {
  position: relative;

  &__items-container {
    display: flex;
    overflow-x: hidden;
    overflow-y: visible;
  }
  &__item {
    min-width: 100%;
    display: flex;
    transition: margin 0.4s ease-out;
    position: relative;
    padding-bottom: 30px;

    &--active {
      .cbgms-carousel__item-cta {
        opacity: 1;
        transition: opacity 0.2s 0.2s ease-out;
      }
    }
  }
  &__item-cta {
    position: absolute;
    right: 0;
    bottom: 0;
    margin-right: 0;
    opacity: 0;
    transition: opacity 0.2s ease-out;
    z-index: 1;
  }
  &__controls {
    position: absolute;
    bottom: 0;
  }
}
