/*******************************
         Site Overrides
*******************************/

.ui.advanced-search {
  .results {
    width: 30em;
  }
}
.ui.search.responsive {
  width: 100% !important;
  padding-right: 0.5rem !important;
  .ui.input.icon {
    width: 100% !important;
  }
}
.ui.search {
  > .results > .result.no-hover {
    cursor: auto;

    &:hover {
      background-color: inherit;
    }
  }
}
