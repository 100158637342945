/*******************************
         Site Overrides
*******************************/
.ui.breadcrumb .active.section {
  font-weight: normal;
}

.ui.breadcrumb a {
  color: @textColor;
}
.ui.breadcrumb a:hover {
  color: @linkHoverColor;
}